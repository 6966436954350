import { Backdrop } from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import UnicoU from '~/components/UnicoU'

const drawerWidth = 230

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  // background: theme.palette.secondary.main,
  // color: '#fff',
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}))

export default function CollapsibleDrawer({
  children,
  hovering,
  persist,
  // open: propsOpen,
  ...rest
}) {
  // const [hovering, setHovering] = React.useState(false)

  const open = hovering || persist

  return (
    <>
      <Backdrop
        open={hovering && !persist}
        // open
        sx={{
          bgcolor: 'rgba(0,0,0,0.1)',
          // bgcolor: 'secondary.main',
          // bgcolor: 'transparent',
          // backdropFilter: 'blur(2px)',
          // transition: 'all 10s',
          zIndex: 1000,
          pointerEvents: 'none',
        }}
      />
      <Drawer
        variant="permanent"
        open={open}
        // onMouseEnter={() => setHovering(true)}
        // onMouseLeave={() => setHovering(false)}
        // onMouseOver={() => setHovering(true)}
        // onMouseOut={() => setHovering(false)}
        // onClick={() => setHovering(false)}
        PaperProps={{
          sx: {
            bgcolor: 'secondary.main',
            // opacity: 0.9 ,
            backdropFilter: 'blur(2px) grayscale(100)',
            color: '#fff',
            '& .MuiListItemIcon-root': { color: 'inherit' },
            minHeight: '100vh',
          },
        }}
        {...rest}
      >
        {children}
      </Drawer>
    </>
  )
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))
