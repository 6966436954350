import Chip from '~/components/Data/Chip'
import useNotifications from '~/routes/Notifications/useNotifications'

export default function NotificationsChip({ sx, ...rest }) {
  const { data } = useNotifications({ search: { dateRead: null } })

  const unread = data?.filter(row => !row.dateRead) || []

  // const maybeMore =
  //   unread?.length > 0 && unread?.length === data.length ? '' : '+'

  return (
    <Chip
      sx={{ ml: 0, mr: 'auto', sx }}
      label={`${unread.length} unread`}
      count={unread.length}
      color={unread.length === 0 ? 'success' : 'warning'}
      {...rest}
    />
  )
}
