import { Box, Card, Tab, Tabs, Typography } from '@mui/material'
import { useParams } from 'react-router'
import StepRouterWindow from '~/core/stepper/StepRouterWindow'
import useParamsStepRouter from '~/core/stepper/useParamsStepRouter'
import sxFlexStack from '~/core/sx/sxFlexStack'
import OverviewHeader from '~/routes/Proposals/Overview/Summary/OverviewHeader'
import Assets from '~/routes/Proposals/Overview/Summary/Assets'
import Distributors from '~/routes/Proposals/Overview/Summary/Distributors'
import OverviewSystemSteps from '~/routes/Proposals/Overview/Summary/OverviewSystemSteps'
import Price from '~/routes/Proposals/Overview/Summary/Price'
import ProjectDetails from '~/routes/Proposals/Overview/Summary/ProjectDetails'
import SizeAndSystems from '~/routes/Proposals/Overview/Summary/SizeAndSystems'
import useProposal from '~/routes/Proposals/Overview/useProposal'
import AutoTable, {
  ModelNumberCell,
} from '~/components/Data/AutoTable/AutoTable'
import { camelToSentence } from '~/core/utils/formatString'
import { usd } from '~/core/utils/formatNumber'
import AuditLogTable from '~/components/Data/AuditLogTable'

export default function ProposalOverview() {
  const { proposalId } = useParams()
  const response = useProposal(proposalId)
  const { data } = response
  console.log('ProposalOverview', data)
  const steps = [
    {
      name: 'summary',
      title: 'Summary',
      children: (
        <Box sx={{ ...sxFlexStack, alignItems: 'stretch' }}>
          <ProjectDetails data={data} />
          <SizeAndSystems data={data} />
          <Price data={data} />
          <Distributors data={{ ...data, proposalId }} />
          <Assets data={data} />
        </Box>
      ),
    },
    {
      name: 'fast-quote',
      title: 'FastQuote',
      children: <OverviewSystemSteps data={data} />,
    },
    {
      name: 'materials',
      title: 'Materials list',
      children: (
        <Card>
          <Box sx={{ p: 2 }}>
            {data?.contents?.distributor?.companyName && (
              <Typography>
                The homeowner price for Unico Equipment is using{' '}
                <b>{data?.contents?.distributor?.companyName}</b> list price.
              </Typography>
            )}
            <Typography>
              The homeowner price includes the owner's company's markup.
            </Typography>
          </Box>
          <AutoTable
            data={data?.contents?.lineItems}
            columns={[
              'description',
              {
                field: 'modelNumber',
                Component: ModelNumberCell,
              },
              { field: 'category', format: camelToSentence },
              'quantity',
              {
                field: 'price',
                headerName: 'Homeowner price',
                format: usd.format,
              },
            ]}
          />
        </Card>
      ),
    },
    {
      name: 'audit-log',
      title: 'Audit log',
      children: (
        <Card>
          <AuditLogTable entityType="proposal" entityId={proposalId} />
        </Card>
      ),
    },
  ]

  const stepProps = useParamsStepRouter({ steps })

  return (
    <>
      <OverviewHeader {...response} />
      <Card sx={{ my: 2 }}>
        <Tabs
          value={stepProps.name}
          variant="scrollable"
          scrollButtons
          onChange={(e, value) => stepProps.stepTo(value)}
        >
          {steps.map(({ name, title }) => (
            <Tab key={name} label={title} value={name} />
          ))}
        </Tabs>
      </Card>
      <StepRouterWindow {...stepProps} />
    </>
  )
}
