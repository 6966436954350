import { Button, Card } from '@mui/material'
import SubmitButton from '~/components/Data/SubmitButton'
import sharedProps from '~/components/sharedProps'
import flexSplitSx from '~/core/sx/flexSplitSx'

export default function StepButtons({
  sx,
  onPrevClick,
  cta = 'Confirm',
  isSubmitting,
  children,
  ...rest
}) {
  return (
    <Card
      sx={theme => ({
        ...flexSplitSx,
        ...sharedProps.card.sx,
        ...sx,
        // [theme.breakpoints.down('sm')]: {
        //   position: 'fixed',
        //   left: 0,
        //   bottom: 0,
        //   right: 0,
        //   width: '100%',
        //   mb: 0,
        // },
      })}
      {...rest}
    >
      {children || (
        <>
          {onPrevClick && (
            <Button variant="contained" onClick={onPrevClick}>
              Back
            </Button>
          )}
          <SubmitButton
            type="submit"
            variant="contained"
            sx={{ ml: 'auto', mr: 0 }}
            isSubmitting={isSubmitting}
          >
            {cta}
          </SubmitButton>
        </>
      )}
    </Card>
  )
}
