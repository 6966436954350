import { Close, ExpandMore, KeyboardArrowRight } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Collapse,
  Dialog,
  Divider,
  IconButton,
  Popover,
  Popper,
} from '@mui/material'
import React from 'react'

export default function ExpandableAlert({
  sx,
  title,
  expandedTitle = title,
  children,
  initOpen,
  onClose,
  severity = 'warning',
  ...rest
}) {
  const [open, setOpen] = React.useState(initOpen)
  return (
    <>
      <Alert
        onClick={e => setOpen(true)}
        severity={severity}
        sx={{
          width: 'auto',
          overflow: 'hidden',
          py: 0.5,
          flexGrow: 1,
          '& > div': { fontWeight: 'bold' },
          ...sx,
          maxHeight: 44,
          cursor: 'pointer',
        }}
        action={
          onClose ? (
            <IconButton onClick={onClose} sx={{ p: 1, m: -0.5, mt: -0.25 }}>
              <Close />
            </IconButton>
          ) : children ? (
            <IconButton sx={{ p: 1, m: -0.5, mt: -0.25 }}>
              <KeyboardArrowRight />
            </IconButton>
          ) : null
        }
        {...rest}
      >
        <AlertTitle
          sx={{
            my: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </AlertTitle>
      </Alert>
      <Dialog onClose={() => setOpen(null)} open={Boolean(open)}>
        <IconButton
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
          onClick={() => setOpen(null)}
        >
          <Close />
        </IconButton>
        <Alert
          severity={severity}
          sx={{
            '& > .MuiAlert-icon': { position: 'absolute' },
            '& > div > .MuiAlertTitle-root': { ml: 4 },
          }}
        >
          <AlertTitle sx={{ fontWeight: 'bold', mb: 2 }}>
            {expandedTitle}
          </AlertTitle>
          {children}
        </Alert>
      </Dialog>
    </>
  )
}
