import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { Link, useMatch } from 'react-router'
import routes from '~/routes'

import styled from '@emotion/styled'
import {
  AddToHomeScreen,
  ContentCopy,
  Feedback,
  HelpOutline,
  Logout,
} from '@mui/icons-material'
import { alpha, Box } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import SentryFeedbackDialog from '~/config/SentryFeedbackDialog'
import useCompanyDetails from '~/routes/Company/Profile/useCompanyDetails'

const NavListItemButton = styled(ListItemButton)(({ theme, active }) => ({
  width: '100%',
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(1.5),
  '&:hover': {
    background: alpha(theme.palette.primary.main, 0.3),
  },
  borderLeft: `${theme.spacing(1)} solid transparent`,
  ...(active && {
    borderLeft: `${theme.spacing(1)} solid ${theme.palette.primary.main}`,
  }),
}))

const NavListItem = ({
  handle: { title, Icon = () => null, target, menuAdornment, nav } = {},
  path = '',
  superPath = '',
  active,
  // sx,
  // onClick,
  children,
  ...rest
}) => {
  const match = useMatch({
    path: [superPath, path].filter(Boolean).join('/'),
    end: false,
  })

  const allowed = nav === true || nav?.()

  if (!allowed) return null

  const redirectPath =
    children?.[0]?.element?.props?.to ||
    children?.find(c => c.handle?.nav === true || c.handle.nav?.())?.path ||
    ''

  return (
    <ListItem disablePadding {...rest}>
      <Link
        to={[superPath, path, redirectPath].filter(Boolean).join('/')}
        target={target}
        viewTransition
      >
        <NavListItemButton active={match ? true : undefined}>
          <ListItemIcon sx={{ minWidth: 48 }}>
            <Icon sx={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText sx={{ color: '#fff' }} primary={title} />
          {menuAdornment?.()}
        </NavListItemButton>
      </Link>
    </ListItem>
  )
}

const SalesAppMenuItems = React.forwardRef(
  ({ sx, initPath = 'app', slotProps, ...rest }, ref) => {
    return (
      <List
        ref={ref}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          // alignItems: 'stretch',
          // justifyContent: 'stretch',
          '& a': { width: '100%' },
          ...sx,
        }}
        {...rest}
      >
        {routes[0].children
          ?.find(r => r.path?.startsWith(initPath))
          ?.children?.map(({ path, handle, children }) => (
            <NavListItem
              {...slotProps?.listItem}
              superPath={`/${initPath}`}
              {...{ path, handle, children }}
              key={path || 'empty'}
            />
          ))}
        <OtherItems />
      </List>
    )
  }
)
export default SalesAppMenuItems

const OtherItems = ({ slotProps }) => {
  // const feedback = useSentryFeedback()
  const [open, setOpen] = React.useState(false)
  const { data } = useCompanyDetails()

  return (
    <>
      <Box sx={{ flexGrow: 1 }} />
      <NavListItem
        path="/sign-out"
        handle={{
          nav: true,
          title: 'Log out',
          Icon: Logout,
        }}
      />
      <SentryFeedbackDialog open={open} onClose={() => setOpen(false)} />
      <ListItem onClick={() => setOpen(true)}>
        <ListItemButton sx={{ p: 0, pl: 0.5 }}>
          <ListItemIcon sx={{ minWidth: 48 }}>
            <Feedback sx={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText sx={{ color: '#fff' }} primary="Feedback" />
        </ListItemButton>
      </ListItem>
      <NavListItem
        path="https://unicosalesapp.zendesk.com/hc/en-us"
        handle={{
          nav: true,
          title: 'Help',
          Icon: HelpOutline,
          target: '_blank',
        }}
      />
      <NavListItem
        path="https://unicosalesapp.com/add-app/"
        handle={{
          nav: true,
          title: 'Add app',
          Icon: AddToHomeScreen,
          target: '_blank',
        }}
      />
      <ListItem
        title="PIN code"
        handle={{ nav: true }}
        sx={{
          transition: 'opacity .3s',
          cursor: 'copy',
          opacity: data?.relationshipPinCode ? 1 : 0,
        }}
        onClick={() =>
          navigator.clipboard
            .writeText(data?.relationshipPinCode)
            .then(res => enqueueSnackbar('PIN copied to clipboard'))
            .catch(
              res =>
                console.error(res) ||
                enqueueSnackbar('Failed to copy', { variant: 'error' })
            )
        }
      >
        <ListItemButton sx={{ p: 0, pl: 0.5 }}>
          <ListItemIcon sx={{ minWidth: 48 }}>
            <ContentCopy sx={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText
            sx={{ color: '#fff' }}
            primary={`Pair code: ${data?.relationshipPinCode}`}
          />
        </ListItemButton>
      </ListItem>
    </>
  )
}
