import { Document, PDFViewer, Text, View } from '@react-pdf/renderer'
import FanLoader from '~/components/Data/FanLoaader'
import { formatDate } from '~/core/utils/formatDate'
import { usd } from '~/core/utils/formatNumber'
import usePublicProposal from '~/routes/ViewProposal/usePublicProposal'
import Table from '~/components/PDF/Table'

import LetterHeader from './LetterHeader'
import PageTemplate from './PageTemplate'
import QRCodes from './QrCodes'
import styles from './styles'

const { b, h1, h2, a } = styles

export default function HomeownerProposalPdf({ proposal }) {
  return (
    <Document>
      <PageTemplate proposal={proposal}>
        <LetterHeader proposal={proposal} />
        <Text style={{ flexGrow: 1 }}>
          {proposal.contents.project.template.contents}
        </Text>
        <QRCodes proposal={proposal} />
      </PageTemplate>

      <PageTemplate proposal={proposal}>
        <Text style={h2}>Your new HVAC system</Text>
        <Text>
          Everything you need to know about getting a HVAC system installed!
        </Text>
        <Hr />
        <Text style={h2}>Your installation timings</Text>
        <View style={styles.gridContainer}>
          <View style={styles.gridItem}>
            <Text style={b}>Estimated start date</Text>
            <Text>{formatDate(proposal.contents.project.startDate)}</Text>
          </View>
          <View style={styles.gridItem}>
            <Text style={b}>Estimated duration</Text>
            <Text>{proposal.contents.project.duration} working days</Text>
          </View>
        </View>
        <Hr />
        <Text style={h2}>Your payment breakdown</Text>
        <View style={styles.gridContainer}>
          {proposal.contents.project.paymentBreakdown.map(
            ({ date, description, percentage }, i) => (
              <View key={date} style={styles.gridItem}>
                <Text style={b}>{description}</Text>
                <Text>{percentage}%</Text>
                <Text>Due: {formatDate(date)}</Text>
              </View>
            )
          )}
          <View style={[styles.gridItem, { width: '100%' }]}>
            <Text>
              Total investment:{' '}
              <Text style={{ display: 'inline', fontWeight: 700 }}>
                {usd.format(proposal.contents.totalPrice)}
              </Text>
            </Text>
          </View>
        </View>
        <Hr />
      </PageTemplate>
      <PageTemplate proposal={proposal}>
        <Text style={h2}>Equipment breakdown</Text>
        <Table
          idKey="modelNumber"
          data={[
            ...proposal.contents.lineItems,
            ...proposal.contents.lineItems,
            ...proposal.contents.lineItems,
          ]}
          columns={[
            { name: 'description', title: 'Description', width: '75%' },
            { name: 'quantity', title: 'Quantity', width: '25%' },
          ]}
        />
      </PageTemplate>
    </Document>
  )
}

const Hr = () => <View style={styles.hr} />

// Create Document Component

export function PdfView() {
  const { proposal } = usePublicProposal()
  return <FanLoader />
  if (!proposal) return <FanLoader />
  console.log(proposal)
  return (
    <>
      {/* <PDFDownloadLink document={<PdfDocument />} fileName="somename.pdf">
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : <Button>Download PDF</Button>
        }
      </PDFDownloadLink> */}
      <PDFViewer style={{ width: '100%', height: '100vh', border: 0 }}>
        <HomeownerProposalPdf proposal={proposal} />
      </PDFViewer>
    </>
  )
}
