import { Link, Text, View } from '@react-pdf/renderer'
import styles from './styles'
import PdfQrUnicoSystem from './PdfQrUnicoSystem'

const { b, h1, h2, a } = styles

export default function QRCodes({ proposal }) {
  return (
    <View style={styles.qrCodes}>
      {/* <PdfQrUnicoSystem /> */}

      <View>
        <Text>
          <Link
            style={a}
            src={`https://salesapp.unicosystem.com/view-proposal/${proposal?.proposalId}`}
          >
            View this proposal online
          </Link>
        </Text>
      </View>
      {/* <View>
        <Text>To view this proposal on</Text>
        <Text>the go, scan the QR code.</Text>
      </View> */}
      <View style={{ flexGrow: 1 }} />
      <View style={{ alignItems: 'flex-end' }}>
        <Text>
          <Link style={a} src="unicosystem.com">
            Learn more about Unico
          </Link>
        </Text>
      </View>
      <PdfQrUnicoSystem />
    </View>
  )
}

/* <Image style={{ width: 64, height: 64 }} /> */
