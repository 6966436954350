import {
  Button,
  Card,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material'

// import { getProposals } from '../FastQuote/Provider/proposalManager'
import {
  Edit as EditIcon,
  EngineeringOutlined,
  Home,
  PostAdd as PostAddIcon,
  Share as ShareIcon,
} from '@mui/icons-material'

import React from 'react'
import client from '~/client'
import AutoTable, {
  ChipCell,
  DateCell,
  TableCell,
} from '~/components/Data/AutoTable/AutoTable'
import MenuCell from '~/components/Data/MenuCell'
import sharedProps from '~/components/sharedProps'
import Link from '~/config/Link'
import ProposalExportDialog, {
  completedProposalExportFields,
} from '~/routes/Proposals/ProposalExportDialog'
import ProposalStatusDialog from '~/routes/Proposals/ProposalStatusDialog'
import { statusElementsArray } from '~/routes/Proposals/statusUtils'

import { useNavigate } from 'react-router'
import AutoSearchMultiCheck from '~/components/Data/AutoTable/AutoSearchMultiCheck'
import AutoSearchTextField from '~/components/Data/AutoTable/AutoSearchTextField'
import { proposalStatusOptions } from '~/components/Data/AutoTable/statusOptions'
import { sxAppStylesFlex } from '~/components/Layouts/AppStyles'
import ActionButtons from '~/components/Layouts/SalesApp/ActionButtons'
import useProposals from '~/routes/Proposals/useProposals'
import addressToArray from '../ViewProposal/addressToArray'

export default function Proposals() {
  // const loaderData = useLoaderData()
  const userInfo = client.getUserInfo()

  const [search, setSearch] = React.useState()

  const { handleDelete, updateStatus, ...response } = useProposals({ search })

  const [proposalExport, setProposalExport] = React.useState()
  const [proposalStatus, setProposalStatus] = React.useState()

  const navigate = useNavigate()

  return (
    <>
      <ProposalExportDialog
        proposal={proposalExport}
        onClose={() => setProposalExport(null)}
      />

      <ProposalStatusDialog
        proposal={proposalStatus}
        onClose={() => setProposalStatus(null)}
        newStatus={proposalStatus?.newStatus}
        updateStatus={updateStatus}
      />

      <ActionButtons sx={{ alignItems: 'center' }}>
        {/* <CompleteSetupAdmin /> */}
        <Link to="/app/proposals/fast-quote">
          <Button variant="contained" endIcon={<PostAddIcon />}>
            New proposal
          </Button>
        </Link>
      </ActionButtons>

      <Card sx={sxAppStylesFlex}>
        <AutoTable
          appStyles
          setSearch={setSearch}
          {...response}
          uniqueField="proposalId"
          // onRowClick={row =>
          //   row.status === 'draft'
          //     ? navigate(
          //         `/app/proposals/fast-quote?proposalId=${row.proposalId}`
          //       )
          //     : navigate(`/app/proposals/my-proposals/${row.proposalId}`)
          // }
          columns={[
            // 'proposalId',
            {
              field: 'createdAt',
              headerName: 'Created',
              Component: DateCell,
              breakpoints: ['sm'],
            },
            {
              // field: 'contents.$-customer-name.string_contains',
              field: 'contents.$-customer-name.string_contains',
              headerName: 'Customer name',
              breakpoints: ['sm'],

              Component: ({ data, ...rest }) => (
                <TableCell {...rest}>{data.contents?.customer?.name}</TableCell>
              ),
            },

            {
              headerName: 'Address',
              field: 'contents.$-customer-address-zipCode.string_contains',
              headerSearch: <AutoSearchTextField placeholder="ZIP code" />,
              Component: ({ data, ...rest }) => (
                <TableCell {...rest}>
                  {addressToArray(data.contents?.customer?.address).join(', ')}
                  {/* {data.contents?.customer?.address?.line1},{' '}
                  {data.contents?.customer?.address?.city},{' '}
                  {data.contents?.customer?.address?.state}, */}
                </TableCell>
              ),
            },
            { field: 'customerEmail' },
            {
              field: 'status',
              headerName: 'Status',
              headerSearch: (
                <AutoSearchMultiCheck options={proposalStatusOptions} />
              ),
              sx: { width: 160 },
              Component: ({ data, ...rest }) => (
                <ChipCell {...rest}>{data.status}</ChipCell>
              ),
            },
            // {
            //   field: 'sentDate',
            //   headerName: 'Sent at',
            //   Component: DateCell,
            // },
            // {
            //   headerName: 'Total price',
            //   Component: ({ data, ...rest }) => (
            //     <TableCell {...rest}>
            //       {data.status === 'sent' &&
            //         usd.format(data.contents?.totalPrice)}
            //     </TableCell>
            //   ),
            // },
            {
              field: 'edit',
              Component: ({ data, ...rest }) =>
                data.status === 'draft' && userInfo.userId === data.userId ? (
                  <TableCell sx={{ px: '0 !important' }}>
                    <Link to="/app/proposals/fast-quote" state={data}>
                      <Button
                        {...sharedProps.button.table}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </Link>
                  </TableCell>
                ) : (
                  <TableCell sx={{ px: '0 !important' }}>
                    <Link
                      to={`/app/proposals/my-proposals/${data.proposalId}`}
                      state={data}
                      // target="view-proposal"
                    >
                      <Button
                        {...sharedProps.button.table}
                        startIcon={<EngineeringOutlined />}
                        // color="success"
                      >
                        View
                      </Button>
                    </Link>
                  </TableCell>
                ),
            },
            // {
            //   field: 'viewMaterials',
            //   headerName: '',
            //   Component: ({ data, ...rest }) => (
            //     <TableCell sx={{ px: '0 !important' }}>
            //       <ProposalExportButton
            //         {...sharedProps.button.table}
            //         // startIcon={<ImportExport />}
            //         proposal={data}
            //       />
            //     </TableCell>
            //   ),
            // },
            // {
            //   field: 'delete',
            //   Component: ({ data, ...rest }) =>
            //     data.status === 'draft' ? (
            //       <TableCell sx={{ px: '0 !important' }}>
            //         <Button
            //           {...sharedProps.button.table}
            //           onClick={() => handleDelete(data)}
            //           color="error"
            //           startIcon={<DeleteOutlineIcon />}
            //         >
            //           Delete
            //         </Button>
            //       </TableCell>
            //     ) : (
            //       <TableCell />
            //     ),
            // },
            {
              field: 'actions',
              Component: ({ data, ...rest }) => (
                <MenuCell onClick={e => e.stopPropagation()}>
                  {/* <Box sx={{ mb: 1 }}>
                        <Typography sx={{ p: 2, pt: 1, fontWeight: 'bold' }}>
                          Proposal actions
                        </Typography>
                        <Divider />
                      </Box> */}
                  {/* <MenuItem onClick={() => console.log(data)}>
                        <ListItemIcon sx={{ color: 'inherit' }}>
                          <DuplicateIcon />
                        </ListItemIcon>
                        <ListItemText>Duplicate</ListItemText>
                      </MenuItem> */}
                  <MenuItem
                    disabled={completedProposalExportFields(data)?.length === 0}
                    onClick={() => setProposalExport(data)}
                  >
                    <ListItemIcon sx={{ color: 'inherit' }}>
                      <ShareIcon />
                    </ListItemIcon>
                    <ListItemText>Materials list</ListItemText>
                  </MenuItem>

                  <Link to={`${data.proposalId}`}>
                    <MenuItem>
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <EngineeringOutlined />
                      </ListItemIcon>
                      <ListItemText>Overview</ListItemText>
                    </MenuItem>
                  </Link>

                  <Link
                    to={`/view-proposal/${data.proposalId}`}
                    target="view-proposal"
                  >
                    <MenuItem>
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <Home />
                      </ListItemIcon>
                      <ListItemText>Homeowner view</ListItemText>
                    </MenuItem>
                  </Link>

                  {data.status === 'sent' && <Divider />}

                  {data.status === 'sent' &&
                    statusElementsArray.map(({ verb, status, icon, color }) => (
                      <MenuItem
                        key={verb}
                        sx={{ color: `${color}.main` }}
                        onClick={() =>
                          setProposalStatus({
                            ...data,
                            newStatus: status,
                          })
                        }
                      >
                        <ListItemIcon sx={{ color: 'inherit' }}>
                          {icon}
                        </ListItemIcon>
                        <ListItemText>{verb}</ListItemText>
                      </MenuItem>
                    ))}
                </MenuCell>
              ),
            },
          ]}
        />
      </Card>
    </>
  )
}
