// import { decode } from 'jsonwebtoken'
import decode from 'jwt-decode'
import ApiError from '~/core/errors/ApiError'

const allRoles = [
  'demo_user',
  'contract_firm_admin',
  'contractor',
  'rep_admin',
  'rep_sales_manager',
  'distributor_admin',
  'distributor_user',
  'distributor_company_admin',
  'distributor_company_user',
  'unico_admin',
  'unico_user',
  'super_admin',
]

export const roles = Object.fromEntries(allRoles.map(role => [role, role]))
export const key = 'auth'

class StackClient {
  constructor(options = {}) {
    const {
      baseUrl = '',
      onError = e => console.error('StackClient error', e),
      onInvalidToken = onError,
      onApiError = onError,
    } = options
    this.baseUrl = baseUrl
    this.onError = onError
    this.onInvalidToken = onInvalidToken
    this.onApiError = onApiError
    this.roles = allRoles
  }

  // Authenticate
  signIn = async ({ username, password }) => {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      }
      const response = await fetch(`${this.baseUrl}auth/signin`, options)

      const json = await response.json()

      // if (json.session) {
      // setSubmitting(false)
      // setUpdatePassword({ session: res.session, username: data.username })
      // } else
      if (json.token) {
        // this.token = json.token
        // this.userInfo = json.data
        this.store({ token: json.token, userInfo: json.data })
      }

      // DEV TEMP
      // if (!this.userInfo?.companyId)
      // apiWarning(`Please reassign your user to a company then sign in again!`)
      return json
    } catch (e) {
      console.error('StackClient sign in error', e)
      this.onError?.(e)
      throw e
    }
  }

  changePassword = async ({ username, password, session }) => {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password, session }),
      }
      const res = await fetch(`${this.baseUrl}auth/changepw`, options)
      const json = await res.json()

      console.log('StackClient change password json response', json)

      await this.signIn({ username, password })

      return json
    } catch (e) {
      console.error('StackClient change password error', e)
      this.onError?.(e)
      throw e
    }
  }

  forgotPassword = async ({ username, confirmationCode, password }) => {
    try {
      let options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }

      let url

      if (confirmationCode && password) {
        options.body = JSON.stringify({ username, confirmationCode, password })
        url = `${this.baseUrl}auth/confirm_forgotpw`
      } else {
        options.body = JSON.stringify({ username })
        url = `${this.baseUrl}auth/forgotpw`
      }

      const res = await fetch(url, options)
      const json = await res.json()

      if (username && password) {
        await this.signIn({ username, password })
      }

      return json
    } catch (e) {
      console.error('StackClient forgot password error', e)
      this.onError?.(e)
      throw e
    }
  }

  fetch = async (path, data, options) => {
    try {
      const res = await fetch(`${this.baseUrl}${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: data ? JSON.stringify(data) : undefined,
        ...options,
      })
      const json = await res.json()
      console.log('StakcClient fetch res', { json })
      if (!json.success)
        throw json?.message?.name || json?.message || 'StackClient fetch error'

      return json.data
    } catch (e) {
      this.onError?.(e)
      throw e
    }
  }

  signOut = () => this.store()

  // Actually get data
  call = async (path, data, method = 'POST') => {
    if (window.navigator.onLine === false) {
      this.onError?.('No internet connection')
      return
    }
    const token = this.retrieve()
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method,
      headers,
      redirect: 'follow',
      body: data ? JSON.stringify(data) : undefined,
    }

    const url = `${this.baseUrl}${path}`

    try {
      const res = await fetch(url, requestOptions)
      const json = await res.json()

      // if (!res.ok)
      //   this.onApiError(
      //     new ApiError('Response not ok', {
      //       url,
      //       ...requestOptions,
      //       json,
      //     })
      //   )

      // dev api format sentry
      if (json.success !== true && typeof json.message !== 'string') {
        this.onApiError(
          new ApiError('Missing `success` Boolean or `message` String', {
            url,
            ...requestOptions,
            json,
          })
        )
      }

      if (json.success) return json.meta ? json : json.data
      else throw json.message || 'Error in API response'
    } catch (e) {
      console.error(e)
      if (e.toString() === 'TypeError: Failed to fetch') {
        this.onApiError(
          new ApiError('TypeError: bad API response', {
            url,
            ...requestOptions,
          })
        )
      }

      if (!this.isTokenValid(token)) {
        this.signOut()
        this.onInvalidToken()
      }
      this.onError?.(e)
      throw e
    }
  }

  page = (path, data, method) => this.call(path, data, 'GET')
  post = this.call
  get = path => this.call(path, null, 'GET')
  put = (path, data) => this.call(path, data, 'PUT')
  delete = (path, data) => this.call(path, data, 'DELETE')
  patch = (path, data) => this.call(path, data, 'PATCH')
  post = this.call
  list = (...args) => this.fetcher(...args).then(json => json.data || res)

  all = async o => {
    const entries = Object.entries(o)
    const res = await Promise.all(entries.map(([k, v]) => v))
    // console.log(res)
    return res.reduce(
      (prev, current, i) => ({ ...prev, [entries[i][0]]: current }),
      {}
    )
  }

  fetcher = async input =>
    typeof input === 'string'
      ? await this.call(input, null, 'GET')
      : await this.call(...input)

  // Get user info
  hasRoles = (input = []) => {
    const userInfo = this.getUserInfo()

    if (!userInfo?.role) return false

    if (userInfo.role === 'super_admin') return true

    return this.hasOnlyRoles(input)
  }

  hasOnlyRoles = (roles = []) => {
    // return true
    // if (typeof input === 'function') return input(this.userInfo.role)
    const userInfo = this.getUserInfo()
    if (typeof roles === 'string') return userInfo.role.includes(roles)

    if (Array.isArray(roles)) return roles.includes(userInfo.role)

    // if (typeof roles === 'function') return roles(userInfo.role)
  }

  isInCompanies = companies => {
    const userInfo = this.getUserInfo()
    if (!userInfo?.companyId) return false
    return companies.includes(userInfo.companyId)
  }

  getUserInfo = () => {
    try {
      const json = localStorage.getItem(key)
      if (!json) return null
      const parsed = JSON.parse(json)
      if (!this.isTokenValid(parsed.token)) localStorage.removeItem(key)
      else return parsed.userInfo
    } catch (e) {
      console.log(e)
    }
  }

  isSignedIn = () => this.retrieve()

  isTokenValid = token => {
    try {
      if (!token) return false
      const decoded = decode(token)
      const currentTime = Date.now() / 1000
      return decoded.exp > currentTime
    } catch (e) {
      console.log(e, 'token invalid')
      return false
    }
  }

  store = ({ token, userInfo } = {}) => {
    try {
      if (token) {
        localStorage.setItem(key, JSON.stringify({ token, userInfo }))
      } else localStorage.removeItem(key)
      window.dispatchEvent(new StorageEvent('local-storage', { key }))
    } catch (error) {
      console.error('erry', error)
    }
  }

  retrieve = () => {
    try {
      const parsed = JSON.parse(localStorage.getItem(key))
      if (!this.isTokenValid(parsed?.token)) localStorage.removeItem(key)
      else return parsed.token
    } catch (e) {
      console.error(e)
    }
  }
}
export default StackClient

// separate because header config is a pain has to not be
// form = async (path, data, method = 'POST') => {
//   try {
//     const token = this.retrieve()
//     const headers = new Headers()
//     headers.append('Authorization', `Bearer ${token}`)

//     const requestOptions = {
//       method,
//       headers,
//       redirect: 'follow',
//       body: data,
//     }

//     const res = await fetch(`${this.baseUrl}${path}`, requestOptions)
//     const json = await res.json()
//     if (json.success) return json.data
//     if (json.message) throw json.message
//     else {
//       apiWarning(`API format error: ${method} to ${path}`, json)
//       return json
//     }
//     // if (!json.success) throw json.message
//   } catch (e) {
//     console.error('StackClient form error', e)
//     this.onError?.(e + 'FORM')
//     throw e
//   }
// }

// TODO: make all calls use this format
