import { Download } from '@mui/icons-material'
import { usePDF } from '@react-pdf/renderer'
import React from 'react'
import SubmitButton from '~/components/Data/SubmitButton'
import HomeownerProposalPdf from '~/components/PDF/HomeownerProposal'
import usePublicProposal from '~/routes/ViewProposal/usePublicProposal'

export default function HomeownerProposalDownloadPdfButton({ ...rest }) {
  const { proposal, isLoading } = usePublicProposal()
  const [{ loading, error, url, blob }, updateInstance] = usePDF()

  React.useEffect(() => {
    if (!proposal) return
    updateInstance(<HomeownerProposalPdf proposal={proposal} />)
  }, [proposal])

  return (
    <>
      <SubmitButton
        variant="text"
        color="inherit"
        href={url}
        endIcon={<Download />}
        disabled={isLoading || loading || error}
        isLoading={isLoading || loading}
        download={`Unico Proposal - ${proposal?.sentDate?.split('T')[0]}.pdf`}
        {...rest}
      >
        {/* {children} */}
        Download PDF
      </SubmitButton>
    </>
  )
}

{
  /* <Link to="download-pdf" target="_blank">
              <Button
                variant="text"
                color="inherit"
                sx={{ mt: 2 }}
                startIcon={<Print />}
              >
                View print version
              </Button>
            </Link>
             */
}
// export function HomeownerProposalDownloadLink() {
//   const downloadPDF = async ({ pdf, name = 'pdf' }) => {
//     const blob = await pdf(pdf).toBlob()
//     const url = URL.createObjectURL(blob)
//     const a = document.createElement('a')
//     a.href = url
//     a.download = `${name}.pdf`
//     document.body.appendChild(a)
//     a.click()
//     document.body.removeChild(a)
//   }

//   const downloadProposalPdf = async proposalId => {
//     const data = await client.get(`publicproposal/?proposalId=${proposalId}`)
//     await downloadPDF({
//       pdf: <HomeownerProposalPdf proposal={data} />,
//       name: `Unico Proposal - ${data.sentDate?.split('T')[0]}`,
//     })
//   }

//   const [downloading, setDownloading] = React.useState(false)
//   const onClick = async () => {
//     try {
//       setDownloading(true)
//       const data = await await downloadPDF({
//         pdf: <HomeownerProposalPdf proposal={proposal} />,
//         name: 'somename',
//       })
//       setDownloading(false)
//     } catch (error) {
//       console.error(error)
//       setDownloading(false)
//     }
//   }

//   return (
//     <PDFDownloadLink
//       document={<HomeownerProposalPdf />}
//       fileName="somename.pdf"
//     >
//       {({ blob, url, loading, error }) =>
//         loading ? 'Loading document...' : <Button>Download PDF</Button>
//       }
//     </PDFDownloadLink>
//   )
// }
