import { Path, Polygon, Svg } from '@react-pdf/renderer'

const PdfQrUnicoSystem = props => (
  <Svg
    fill="#000"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45.35 45.63"
    width={64}
    height={64}
    {...props}
  >
    <Path d="M12.23,6.95c0-.9-.72-1.64-1.6-1.64h-4.38v4.35c0,.9.72,1.64,1.61,1.64h4.38v-4.35h0ZM7.85,12.58c-1.59,0-2.89-1.31-2.89-2.92v-5.63s5.67,0,5.67,0c1.59,0,2.89,1.31,2.89,2.92v5.63h-5.67Z" />
    <Path d="M8.41,10.12c-.09,0-.17-.01-.25-.03-.17-.05-.33-.14-.45-.26-.02-.02-.04-.05-.06-.07-.14-.17-.23-.4-.23-.64v-2.64s2.65,0,2.65,0c.55,0,.99.45.99,1.01v1.63h0s0,1.01,0,1.01h-2.65Z" />
    <Path d="M6.24,38.17h4.38c.89,0,1.61-.73,1.61-1.64v-4.35s-4.38,0-4.38,0c-.88,0-1.6.73-1.6,1.64v4.35h0ZM4.96,39.46v-5.63c0-1.61,1.3-2.92,2.89-2.92h5.67v5.63c0,1.61-1.3,2.92-2.89,2.92h-5.67Z" />
    <Path d="M10.07,37c.09,0,.17-.01.25-.03.17-.05.33-.14.45-.26.02-.02.04-.05.06-.07.14-.17.23-.4.23-.64v-2.64s-2.65,0-2.65,0c-.55,0-.99.45-.99,1.01v1.63h0s0,1.01,0,1.01h2.65Z" />
    <Path d="M39.11,5.31h-4.38c-.89,0-1.61.73-1.61,1.64v4.35s4.38,0,4.38,0c.88,0,1.6-.73,1.6-1.64v-4.35h0ZM40.39,4.02v5.63c0,1.61-1.3,2.92-2.89,2.92h-5.67v-5.63c0-1.61,1.3-2.92,2.89-2.92h5.67Z" />
    <Path d="M35.28,6.48c-.09,0-.17.01-.25.03-.17.05-.33.14-.45.26-.02.02-.04.05-.06.07-.14.17-.23.4-.23.64v2.64s2.65,0,2.65,0c.55,0,.99-.45.99-1.01v-1.63h0s0-1.01,0-1.01h-2.65Z" />
    <Polygon points="22.56 19.63 22.56 19.63 22.56 19.63 22.56 19.63 22.56 19.63" />
    <Path d="M16.57,38.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M21.46,38.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,38.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,38.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,38.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,38.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M19.01,37.62h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M23.9,37.62h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,37.62h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,37.62h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,37.62h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M33.67,37.62h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,37.62h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M38.56,37.62h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M20.23,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M25.12,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M32.45,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M39.78,36.4h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,35.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M19.01,35.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M20.23,35.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,35.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M25.12,35.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,35.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M32.45,35.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,35.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,35.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M38.56,35.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M39.78,35.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M21.46,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M23.9,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M32.45,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M33.67,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M39.78,33.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M19.01,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M23.9,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M25.12,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M38.56,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M39.78,32.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,31.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,31.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M21.46,31.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,31.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M23.9,31.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,31.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,31.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,31.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M32.45,31.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,31.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,31.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M39.78,31.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M21.46,30.29h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M23.9,30.29h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,30.29h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,30.29h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,30.29h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,30.29h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,30.29h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M38.56,30.29h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M5.57,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M6.79,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M8.02,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M10.46,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M12.9,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M14.12,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M17.79,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M20.23,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M21.46,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M25.12,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M32.45,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M33.67,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M38.56,29.07h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M5.57,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M10.46,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M19.01,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M21.46,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M25.12,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M32.45,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M33.67,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M39.78,27.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M5.57,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M9.24,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M10.46,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M12.9,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M14.12,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M17.79,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M21.46,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M38.56,26.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M5.57,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M9.24,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M10.46,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M11.68,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M17.79,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M32.45,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M38.56,25.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M6.79,24.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M10.46,24.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M12.9,24.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M14.12,24.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,24.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M17.79,24.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,24.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,24.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M33.67,24.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,24.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,24.18h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M5.57,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M6.79,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M8.02,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M9.24,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M10.46,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M17.79,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M38.56,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M39.78,22.96h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M5.57,21.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M6.79,21.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M9.24,21.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M12.9,21.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M14.12,21.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,21.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,21.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M33.67,21.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,21.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,21.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M38.56,21.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M39.78,21.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M5.57,20.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M6.79,20.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M8.02,20.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,20.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M17.79,20.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,20.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,20.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,20.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M38.56,20.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M39.78,20.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M6.79,19.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M9.24,19.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M12.9,19.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,19.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M17.79,19.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,19.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,19.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,19.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M33.67,19.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,19.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,19.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M5.57,18.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M8.02,18.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M10.46,18.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,18.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,18.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,18.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,18.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M32.45,18.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M33.67,18.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,18.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M5.57,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M10.46,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M11.68,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M12.9,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M23.9,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M32.45,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M38.56,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M39.78,16.85h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M5.57,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M8.02,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M10.46,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M20.23,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M25.12,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M32.45,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M39.78,15.63h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M8.02,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M9.24,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M10.46,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M11.68,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M12.9,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M14.12,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M19.01,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M23.9,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M31.23,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M33.67,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M34.9,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M36.12,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M37.34,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M39.78,14.41h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M17.79,13.19h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M20.23,13.19h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M21.46,13.19h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,13.19h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,13.19h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,13.19h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,13.19h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,11.97h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M17.79,11.97h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M20.23,11.97h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,11.97h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M25.12,11.97h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,11.97h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,11.97h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,10.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,10.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M20.23,10.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M21.46,10.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,10.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M25.12,10.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,10.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,10.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,10.74h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,9.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M19.01,9.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M21.46,9.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,9.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M23.9,9.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,9.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,9.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,9.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,9.52h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,8.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M20.23,8.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,8.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M25.12,8.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,8.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,8.3h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,7.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,7.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M17.79,7.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M21.46,7.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,7.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M23.9,7.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M25.12,7.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,7.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,7.08h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M16.57,5.86h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M19.01,5.86h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M20.23,5.86h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,5.86h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M25.12,5.86h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,5.86h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M15.35,4.64h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M17.79,4.64h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M19.01,4.64h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M22.68,4.64h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M23.9,4.64h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M25.12,4.64h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M26.34,4.64h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M27.56,4.64h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M28.79,4.64h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M30.01,4.64h.61c0-.34-.27-.61-.61-.61s-.61.27-.61.61.27.61.61.61.61-.27.61-.61h-.61Z" />
    <Path d="M24.41,22.95c-.03,1.25-.46,1.79-1.74,1.79-1.69,0-1.73-1.18-1.73-1.93v-4.07h1.37v4.24c0,.47,0,.82.36.82s.37-.35.37-.82v-4.24h1.37v4.21h0Z" />
  </Svg>
)
export default PdfQrUnicoSystem
