import client from '~/client'
import {
  useContractorNetworkCompanies,
  useDistributorNetworkCompanies,
} from '~/components/Companies/useNetworkCompanies'
import useUsers from '~/components/Users/useUsers'
import { camelToSentence } from '~/core/utils/formatString'
import useCompanyAssets from '~/routes/Company/Assets/useCompanyAssets'
import useCompanyDetails from '~/routes/Company/Profile/useCompanyDetails'

const defaultIsValid = (data, key) =>
  data?.[key]?.length > 0 || Boolean(data?.[key])

const assessValidity = (data, criteria, o) =>
  data &&
  criteria
    .filter(({ key, isValid = defaultIsValid, message }) => !isValid(data, key))
    .map(
      ({
        key,
        title = camelToSentence(key),
        message = `${title} is not valid, please set it up`,
      }) => ({
        ...o,
        key,
        title,
        message,
      })
    )

const proposalCriteria = [
  {
    key: 'markupRate',
    title: 'Markup rate',
    message:
      'Markup rate is the percentage you add to your material cost to determine the homeowner sell price.',
  },
  {
    key: 'expiryTime',
    title: 'Expiry time',
    message:
      'Expiry time is the number of days until proposals that are sent expire.',
  },
  {
    key: 'exclusionsTemplate',
    title: 'Exclusions template',
    message:
      'Standard exclusions included in all proposals. Outline anything your company won’t cover or do. Attach any related documents.',
    isValid: (data, key) =>
      defaultIsValid(data, 'exclusionsTemplate') ||
      defaultIsValid(data, 'exclusionsTemplateFile'),
  },
  {
    key: 'labourRates',
    title: 'Labour rates',
    message:
      'Add in your firms roles (e.g. engineer) and their day rate (e.g. $500) to be added to the proposal total price. These rates are not marked up (specify the homeowner sell price).',
  },
]

// contract_firm_admin
export function useCompleteSetupProposal() {
  const { data } = useCompanyDetails()
  if (typeof data === 'undefined') return
  if (!client.hasOnlyRoles('contract')) return

  const items = client.hasRoles('contract_firm_admin')
    ? assessValidity(data, proposalCriteria)
    : assessValidity(data, proposalCriteria, { admin: true })

  return {
    title: 'Proposal settings',
    url: '/app/proposals/settings',
    items,
  }
}

// any 'admin'
const companyCriteria = [
  {
    key: 'primaryColor',
    message: "To brand proposals and emails, set your company's primary color.",
  },
  {
    key: 'logo',
    message: 'To brand proposals and emails, upload your companies logo.',
  },
]

export function useCompleteSetupCompany() {
  const { data } = useCompanyDetails()
  if (typeof data === 'undefined') return

  const items = client.hasRoles('admin')
    ? assessValidity(data, companyCriteria)
    : assessValidity(data, companyCriteria, { admin: true })

  return {
    title: 'Company profile',
    url: '/app/company/profile',
    items,
  }
}

export function useCompleteSetupDistributorBranch() {
  const { data } = useCompanyDetails()
  if (typeof data === 'undefined') return
  if (!client.hasRoles('distributor_admin')) return
  if (typeof data.distributorMultiplier === 'number') return

  return {
    title: 'Distributor pricing',
    url: '/app/pricing/company-pricing/',
    items: [
      {
        title: 'Contractor multiplier',
        message:
          'Set your branch sell price by inputting your Unico multiplier. All Unico products will be sold at this rate to paired Contractors.',
      },
    ],
  }
}

// any 'admin'
export function useCompleteSetupUsers() {
  const { data, pagination } = useUsers()
  if (typeof pagination?.totalItems === 'undefined') return
  if (pagination?.totalItems > 1) return

  if (client.hasRoles('admin'))
    return {
      url: '/app/company/users',
      title: 'Add your first user',
      message:
        'Click the "Add new user" button then add your user\'s name, email and role then send them their confirmation email',
    }
}

// any 'admin'
export function useCompleteSetupAssets() {
  const { assets, data = assets, pagination } = useCompanyAssets()
  if (typeof data?.length === 'undefined') return
  if (data?.length > 0) return

  return {
    admin: !client.hasRoles('admin'),
    url: '/app/company/assets',
    title: 'Add your first asset',
    message:
      'Assets are supporting documents and images to be used in your proposals. Click the "Add new asset" button to upload your assets.',
  }
}

export function useCompleteSetupRelationshipsWithContractingFirms() {
  const { data, pagination } = useContractorNetworkCompanies()
  if (typeof pagination?.totalItems === 'undefined') return
  if (pagination?.totalItems > 0) return

  if (client.hasOnlyRoles(['rep_admin', 'rep_sales_manager']))
    return {
      url: '/app/relationships/contracting-firms',
      title: 'Create a contracting firm',
      message: 'Create a contracting firm to represent',
    }

  if (client.hasOnlyRoles(['distributor_user', 'distributor_admin']))
    return {
      url: '/app/relationships/contracting-firms',
      title: 'Create or pair with a contracting firm',
      message:
        'Allow contractors to use your branch pricing by sharing your PIN or create a new Contracting company and add users by clicking "Add New Company".',
    }
}

export function useCompleteSetupRelationshipsWithBranches() {
  const { data, pagination } = useDistributorNetworkCompanies()
  if (typeof pagination?.totalItems === 'undefined') return
  if (pagination?.totalItems > 0) return

  const url = '/app/relationships/distributor-branches'

  if (client.hasOnlyRoles(['contract_firm_admin']))
    return {
      url,
      title: 'Pair with your first branch',
      message:
        'Connect with local branches to use their Unico list pricing. Contact them for their PIN to pair.',
    }

  if (
    client.hasOnlyRoles([
      'distributor_company_user',
      'distributor_company_admin',
    ])
  )
    return {
      title: 'Create a branch',
      message: 'Create a branch for contractors to buy from',
      url,
    }

  if (client.hasOnlyRoles(['rep_admin', 'rep_sales_manager']))
    return {
      title: 'Create a branch',
      message:
        'Connect with local branches to use their Unico list pricing. Contact them for their PIN to pair.',
      url,
    }
}

export default function useCompleteSetup() {
  const hooks = [
    useCompleteSetupProposal(),
    useCompleteSetupCompany(),
    useCompleteSetupUsers(),
    useCompleteSetupAssets(),
    useCompleteSetupRelationshipsWithContractingFirms(),
    useCompleteSetupRelationshipsWithBranches(),
    useCompleteSetupDistributorBranch(),
  ]

  const scopes = hooks.flatMap(scope => {
    if (!scope || (Array.isArray(scope.items) && scope.items.length === 0))
      return []
    if (scope?.items?.length > 0) return scope
    return { ...scope, items: [scope] }
  })

  return scopes
}
