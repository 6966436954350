import {
  Button,
  Card,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material'

import {
  EngineeringOutlined,
  Home,
  RemoveRedEye as ViewIcon,
} from '@mui/icons-material'
import React from 'react'
import usePageQuery from '~/client/usePageQuery'
import AutoTable, {
  ChipCell,
  DateCell,
  TableCell,
} from '~/components/Data/AutoTable/AutoTable'
import { sxAppStylesFlex } from '~/components/Layouts/AppStyles'
import sharedProps from '~/components/sharedProps'
import Link from '~/config/Link'
import { completedProposalExportFields } from '~/routes/Proposals/ProposalExportDialog'
import MenuCell from '~/components/Data/MenuCell'
import client from '~/client'
import isDev from '~/config/isDev'

export default function RepGroupProposals({
  canView = client.hasRoles(['rep_admin', 'rep_sales_manager']),
}) {
  const [search, setSearch] = React.useState()
  const response = usePageQuery(`repCompanyRelations/proposals`, { search })

  // const { data: companyRelations } = useCompanies({
  //   query: 'repCompanyRelations/companies',
  // })
  // maybe memoize
  // const companiesOptions = companyRelations?.map(({ contractingFirm }) => ({
  //   label: contractingFirm.companyName,
  //   value: contractingFirm.companyId,
  //   id: contractingFirm.companyId,
  // }))

  console.log('response', response)

  return (
    <>
      <Card sx={sxAppStylesFlex}>
        <AutoTable
          appStyles
          setSearch={setSearch}
          {...response}
          uniqueField="proposalId"
          columns={[
            // 'proposalId',
            {
              field: 'createdAt',
              headerName: 'Created',
              Component: DateCell,
              breakpoints: ['sm'],
            },
            {
              field: 'company.companyName',
              sortable: true,
              headerName: 'Contracting company',
              breakpoints: ['sm'],
              Component: ({ data, ...rest }) => (
                <TableCell {...rest}>
                  {data.contents?.company?.companyName}
                </TableCell>
              ),
            },
            {
              field: 'contractor.firstName',
              sortable: true,
              headerName: 'Contractor',
              field: 'user.firstName',
              sortable: true,
              breakpoints: ['sm'],
              Component: ({ data, ...rest }) => (
                <TableCell {...rest}>
                  {data.contents?.contractor?.firstName}{' '}
                  {data.contents?.contractor?.lastName}
                </TableCell>
              ),
            },
            {
              headerName: 'Address',
              Component: ({ data, ...rest }) => (
                <TableCell {...rest}>
                  {data.contents?.customer?.address?.zip},
                  {data.contents?.customer?.address?.state}
                </TableCell>
              ),
            },
            {
              headerName: 'Status',
              field: 'status',
              Component: ({ data, ...rest }) => (
                <ChipCell {...rest}>{data.status}</ChipCell>
              ),
            },
            canView && {
              field: 'actions',
              Component: ({ data, ...rest }) => (
                <MenuCell onClick={e => e.stopPropagation()}>
                  {/* <MenuItem
                    disabled={completedProposalExportFields(data)?.length === 0}
                    onClick={() => setProposalExport(data)}
                  >
                    <ListItemIcon sx={{ color: 'inherit' }}>
                      <ShareIcon />
                    </ListItemIcon>
                    <ListItemText>Materials list</ListItemText>
                  </MenuItem> */}

                  <Link to={`${data.proposalId}`}>
                    <MenuItem>
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <EngineeringOutlined />
                      </ListItemIcon>
                      <ListItemText>Overview</ListItemText>
                    </MenuItem>
                  </Link>

                  <Link
                    to={`/view-proposal/${data.proposalId}`}
                    target="view-proposal"
                  >
                    <MenuItem>
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <Home />
                      </ListItemIcon>
                      <ListItemText>Homeowner view</ListItemText>
                    </MenuItem>
                  </Link>

                  {/* {data.status === 'sent' && <Divider />} */}

                  {/* {data.status === 'sent' &&
                    statusElementsArray.map(({ verb, status, icon, color }) => (
                      <MenuItem
                        key={verb}
                        sx={{ color: `${color}.main` }}
                        onClick={() =>
                          setProposalStatus({
                            ...data,
                            newStatus: status,
                          })
                        }
                      >
                        <ListItemIcon sx={{ color: 'inherit' }}>
                          {icon}
                        </ListItemIcon>
                        <ListItemText>{verb}</ListItemText>
                      </MenuItem>
                    ))} */}
                </MenuCell>
              ),
            },
            // {
            //   field: 'view',
            //   headerName: '',
            //   Component: ({ data, ...rest }) => (
            //     <TableCell sx={{ px: '0 !important' }}>
            //       <Link
            //         to={`/view-proposal/${data.proposalId}`}
            //         state={data}
            //         target="view-proposal"
            //       >
            //         <Button
            //           {...sharedProps.button.table}
            //           startIcon={<ViewIcon />}
            //           color="success"
            //         >
            //           View
            //         </Button>
            //       </Link>
            //     </TableCell>
            //   ),
            // },
          ]}
        />
      </Card>
    </>
  )
}
