import { Box, Button, Collapse, Stack } from '@mui/material'
import { FormContainer, useForm } from 'react-hook-form-mui'

import { Replay, Search } from '@mui/icons-material'
import Close from '@mui/icons-material/Close'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import debounce from 'lodash.debounce'
import React from 'react'

export default function SearchFormContainer({
  children,
  setSearch,
  defaultValues,
  // in: propsIn,
}) {
  const formContext = useForm({ defaultValues, shouldUnregister: true })

  const handleSuccess = data => setSearch(data)
  const debouncedHandleSuccess = debounce(handleSuccess, 500, {
    leading: true,
  })

  // submit on change
  const { handleSubmit, watch } = formContext
  React.useEffect(() => {
    const subscription = watch(handleSubmit(debouncedHandleSuccess))
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  const containerRef = React.useRef()
  React.useEffect(() => {
    if (containerRef.current)
      containerRef.current.querySelector('input[type=text]')?.focus()
  }, [containerRef.current])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormContainer
        formContext={formContext}
        // onSuccess={handleSuccess}
      >
        {children}
      </FormContainer>
    </LocalizationProvider>
  )
}

// const reset = () => {
//   formContext.reset()
//   setSearch({})
// }

// const [open, setOpen] = React.useState(alwaysOpen || defaultOpen)

// const toggleOpen = () => {
//   setOpen(v => !v)
//   reset()
// }
