import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import FormHelperText from '~/core/forms/theme/FormHelperText'
import * as colors from './colors'
import palette from './palette'
import shadows from './shadows'
import typography from './typography'

let theme = createTheme({
  custom: { colors },
  // palette: {
  //   primary: {
  //     main: '#17609e',
  //   },
  //   secondary: {
  //     main: '#7ceff3',
  //   },
  // },
  palette,
  shadows,
  shape: {
    borderRadius: 8,
  },
  // shadows: [
  //   'none',
  //   // '0px 4px 6px rgba(0, 0, 0, 0.12);',
  //   // '0px 4px 10px rgba(0, 0, 0, 0.23);',
  //   // '0px 4px 20px rgba(0, 0, 0, 0.23);',
  // ],
  typography,
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 165,
      leavingScreen: 120,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          fontWeight: 600,
          textDecoration: 'underline',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        // variant: 'outlined',
        variant: 'contained',
        color: 'primary',
        disableElevation: true,
      },
      styleOverrides: {
        root: { padding: 16, paddingTop: 8, paddingBottom: 8, borderRadius: 4 },
        // outlined: {
        //   borderWidth: 2,
        //   '&:hover': { borderWidth: 2 },
        //   '&.Mui-disabled': { borderWidth: 2 },
        // },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      // styleOverrides: {
      //   root: {
      //     borderRadius: 4,
      //   },
      // },
    },

    MuiToggleButton: {
      // works
      styleOverrides: {
        root: {
          borderWidth: '2px !important',
          '&.Mui-selected': {
            border: '2px solid red',
          },
        },
      },
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': { width: 0 },
          overflow: 'hidden',
          transition: 'width 0.5s',
          width: 28,
        },
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       '&.MuiInputBase-inputSizeSmall': {
    //         padding: 0,
    //       },
    //     },
    //   },
    // },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&&& > fieldset': {
            borderWidth: '2px !important',
          },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              appearance: 'none',
            },
          '& .MuiInputBase-sizeSmall': {
            // paddingLeft: 4,
            paddingRight: 2,
            // '&.MuiInputBase-adornedEnd': { paddingRight: 2 },
            '& input[type=number]': {
              paddingRight: 0,
              paddingLeft: 8,
            },
            '& .MuiInputAdornment-sizeSmall': {
              marginLeft: 2,
              paddingRight: 4,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& tbody tr:last-child td, & tbody tr:last-child th': { border: 0 },
          '& td:last-child, & th: last-child': { paddingRight: 8 },
          '& td:first-child, & th: first-child': { paddingLeft: 8 },
          '& tr:[onclick]:hover': {
            background: '#f7f7f7',
            cursor: 'pointer',
          },
        },
      },
    },
    // MuiTableContainer: {
    //   styleOverrides: {
    //     root: {
    //       maxWidth: 500,
    //     },
    //   },
    // },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          '& .MuiInputAdornment-positionEnd': {
            alignSelf: 'start',
            height: '100%',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        square: true,
      },
    },
    MuiGrid: {
      defaultProps: {
        xs: 12,
      },
      styleOverrides: {
        container: {
          '&': { maxWidth: 'calc(100% + 24px)' },
        },
        item: {
          // TODO: problems
          // '&': { maxWidth: '100%' },
        },
      },
    },
    MuiGrid2: {
      defaultProps: {
        xs: 12,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: { display: 'flex', alignItems: 'center', '& > a': { order: 2 } },
        asterisk: { color: 'red', order: 1 },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'rgba(29, 88, 154, 1)',
        },
      },
    },
    MuiAlert: {
      styleOverrides: { action: { padding: 0 } },
    },
    // MuiToggleButtonGroup: {
    //   styleOverrides: {
    //     root: { flexWrap: 'wrap' },
    //     grouped: ({ theme }) => ({
    //       '&:not(:last-of-type)': {
    //         marginRight: 8,
    //       },
    //       marginBottom: 8,
    //       border: '2px solid rgba(0, 0, 0, 0.12) !important',
    //       // border: '2px solid rgba(0, 0, 0, 0.12)',
    //       borderRadius: '4px !important',
    //       '&.Mui-selected': {
    //         border: `2px solid ${alpha(
    //           theme.palette.secondary.main,
    //           0.7
    //         )} !important`,
    //       },
    //       '.Mui-error + &': {
    //         border: `2px solid ${alpha(
    //           theme.palette.error.dark,
    //           0.6
    //         )} !important`,
    //       },
    //     }),
    //   },
    // },
    // MuiFormHelperText: {
    //   defaultProps: {
    //     as: motion.div,
    //     initial: { height: 0 },
    //     animate: { height: 'auto' },
    //     exit: { height: 0 },
    //   },
    // },
    MuiCssBaseline: {
      // styleOverrides: (themeParam) => ({
      //   body: themeParam.palette.mode === 'dark' ? darkScrollbar() : null,
      // }),
      styleOverrides: ({
        typography: { body1, a, h1, h2, h3, h4, h5, h6 },
        fontFamily,
      }) => ({
        a,
        p: body1,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        html: {
          scrollBehavior: 'smooth',
        },
        body: {
          background: '#F9F9FC',
          '& header': {},
          '& footer': {
            background: colors.charcoal,
            color: 'white',
          },
        },
        'p, h1, h2, h3, h4, h5, h6': {
          margin: 0,
          // width: '100%',
          // marginBottom: 16,
          marginBottom: '0.35em',
        },
        button: {
          border: 'none',
          cursor: 'pointer',
          background: 'transparent',
        },
        // '*': { boxSixing: 'border-box' },
      }),
    },
    MuiSnackbar: {
      defaultProps: {
        variant: 'info',
      },
      styleOverrides: {
        root: {
          '& .super': {
            background: 'purple',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {},
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        as: FormHelperText,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 20,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 20,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 20,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // fontSize: 13,
          background: 'white',
          color: '#555',
        },
      },
    },
  },
})

typeof window !== 'undefined' && console.log('theme', theme)

theme = responsiveFontSizes(theme, {
  // factor: 2,
  // breakpoints: ['xs', 'sm', 'md', 'lg'],
})

export default theme
