import { Card, Typography } from '@mui/material'
import { camelToSentence } from '~/core/utils/formatString'
import sxCardInfo from '~/routes/Proposals/Overview/Summary/sxCardInfo'

export default function SizeAndSystems({ data, sx, ...rest }) {
  const length = data?.contents?.systems?.length

  if (!length) return null

  return data?.contents?.systems.map((system, i) => (
    <Card sx={{ ...sxCardInfo, ...sx }} {...rest}>
      <Typography variant="h4">
        Size &amp; system {length > 1 ? `(${length})` : ''}
      </Typography>
      <div>
        {Object.entries({ tonnage: system.size?.tonnage, ...system.system })
          .filter(([key, value]) => typeof value === 'string')
          .map(([key, value]) => (
            <div>
              <Typography key={key} variant="h6">
                {camelToSentence(key)}
              </Typography>
              {camelToSentence(value)}
            </div>
          ))}
      </div>
    </Card>
  ))
}
