import { useLocation } from 'react-router'
import useCompleteSetup from '~/routes/CompleteSetup/useCompleteSetup'

const useCompleteSetupScope = ({ pathname: propsPathname } = {}) => {
  const location = useLocation()
  const pathname = propsPathname || location.pathname
  const scopes = useCompleteSetup()
  return scopes.find(item => item.url === pathname)?.items
}

export default useCompleteSetupScope
