import MenuIcon from '@mui/icons-material/Menu'
import {
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  IconButton,
  useMediaQuery,
} from '@mui/material'
import React from 'react'
import { Outlet, useMatches, useLocation, useBlocker } from 'react-router'
import UnicoLogo from '../UnicoLogo'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useMultiPortalOutlet } from '../MultiPortal'
import ScrollToTop from '../ScrollToTop'
import CollapsibleDrawer from './CollapsibleDrawer'
import PageHeader from './PageHeader'
import SalesAppMenuItems from './SalesAppMenuItems'
import {
  ArrowBackIos,
  Close,
  PushPin,
  PushPinOutlined,
} from '@mui/icons-material'
import UnicoU from '~/components/UnicoU'

const sideBarWidth = 230
const persistBreakpoint = 'xl'

export default function LayoutSalesApp() {
  const isLarge = useMediaQuery(theme =>
    theme.breakpoints.up(persistBreakpoint)
  )
  const [hovering, setHovering] = React.useState(false)
  const [persist, setPersist] = React.useState(isLarge)

  const matches = useMatches()
  const title = matches[2]?.handle?.title || ' '

  const [open, setOpen] = React.useState(false)
  const toggleMenu = () => setOpen(o => !o)

  // const location = useLocation()

  // this prevents sidebar poppping up again after enabled viewTransition on links
  // doesn't work
  // const hasJustNavigated = React.useRef(false)
  // const onMouseEnter = () => {
  //   console.log('onMouseEnter', hasJustNavigated.current)
  //   if (!hasJustNavigated.current) setHovering(true)
  // }
  // React.useEffect(() => {
  //   let id
  //   hasJustNavigated.current = true
  //   id = setTimeout(() => {
  //     hasJustNavigated.current = false
  //   }, 300)
  //   return () => clearTimeout(id)
  // }, [location])

  // useBlocker(({ currentLocation, nextLocation }) => {
  //   hasJustNavigated.current = true
  //   setTimeout(() => {
  //     hasJustNavigated.current = false
  //   }, 300)
  //   return false
  // })

  // const onMouseEnter = () => {
  //   if (!hasJustNavigated.current) setHovering(true)
  // }
  // const [clicked, setClicked] = React.useState(false)
  const clicked = React.useRef(false)
  const setClicked = href => {
    clicked.current = href
    setTimeout(() => (clicked.current = false), 700)
  }
  const onMouseEnter = e => {
    // console.log('ENTER', e.target.closest('a')?.getAttribute('href'))
    if (clicked.current === e.target.closest('a')?.getAttribute('href')) return
    setHovering(true)
  }
  const onClick = e => {
    // console.log('CLICK', e.target.closest('a')?.getAttribute('href'))
    setClicked(e.target.closest('a')?.getAttribute('href'))
    setHovering(false)
  }

  return (
    <>
      <ScrollToTop />

      <AppBar
        key="appBar"
        position="fixed"
        sx={{
          bgcolor: 'secondary.main',
          display: { md: 'none', maxHeight: '100vh' },
        }}
      >
        <Toolbar>
          <UnicoLogo
            width={140}
            height={28}
            sx={{ marginRight: 'auto', marginLeft: 0 }}
          />
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={toggleMenu}
            size="large"
            edge="end"
          >
            {open ? <Close /> : <MenuIcon />}
          </IconButton>
          {/* <Button
            onClick={toggleMenu}
            variant="text"
            color="inherit"
            size="large"
            endIcon={open ? <Close /> : <MenuIcon />}
          >
            menu
          </Button> */}
        </Toolbar>
        {/* <ClickAwayListener
          onClickAway={open ? () => setOpen(false) : undefined}
        > */}
        <Collapse in={open} sx={{ overflowY: 'auto', p: 0 }}>
          <SalesAppMenuItems
            sx={{ pb: 4, 'li:has(a[href="/sign-out"])': { mt: 8 } }}
            onClick={() => setOpen(false)}
            initPath="app"
            // sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
          />
        </Collapse>
        {/* </ClickAwayListener> */}
      </AppBar>

      <CollapsibleDrawer
        persist={persist || isLarge}
        hovering={hovering}
        sx={{ display: { xs: 'none', md: 'block', position: 'relative' } }}
        onMouseLeave={() => setHovering(false)}
        onMouseEnter={onMouseEnter}
        onClick={() => {
          // setBlockHover(true)
          // setHovering(false)
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pt: 3,
            transition: 'opacity .1s',
            opacity: hovering || persist || isLarge ? 0 : 1,
            pointerEvents: 'none',
          }}
        >
          <UnicoU />
        </Box>
        <Box
          sx={{
            pt: 2,
            pb: 2,
            opacity: hovering || persist || isLarge ? 1 : 0,
            transition: 'all .2s',
            // width: 130,
            width: '100%',
            px: 2.5,
            position: 'relative',
            left: hovering || persist || isLarge ? 0 : -140,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <UnicoLogo
            sx={{
              width: '66%',
              height: 'auto',
              // '&:hover path.U': {
              //   transform: 'scale(1.5) translate(6%, -23%)',
              // },
            }}
          />
          <IconButton
            edge="end"
            sx={{
              color: 'inherit',
              opacity: persist ? 1 : 0.5,
              // opacity: 0.5,
              // display: { xs: 'none', md: 'block', [persistBreakpoint]: 'none' },
              visibility: { md: 'visible', [persistBreakpoint]: 'hidden' },
            }}
            onClick={() => {
              setPersist(p => !p)
              setTimeout(() => setHovering(false))
            }}
          >
            {persist ? <ArrowBackIos /> : <PushPinOutlined />}
          </IconButton>
        </Box>

        <SalesAppMenuItems
          slotProps={{ listItem: { onMouseEnter, onClick } }}
          initPath="app"
        />
      </CollapsibleDrawer>

      <Box
        component="main"
        sx={{
          pb: { xs: 2, sm: 3, md: 3, lg: 4 },
          px: { xs: 1, sm: 3, md: 3, lg: 5 },
          pt: { xs: 8, sm: 10, md: 0 },
          // ...(hasSideBar && { ml: { md: `${sideBarWidth}px` } }),
          ml: {
            xs: 0,
            md: persist ? `${sideBarWidth}px` : 8,
            [persistBreakpoint]: `${sideBarWidth}px`,
          },
          containerType: 'inline-size',
          containerName: 'main',
          transition: 'all 0.3s',
          maxWidth: 'xl',
        }}
      >
        <PageHeader app title={useMultiPortalOutlet('title', title)} />
        <Outlet />
      </Box>
    </>
  )
}
