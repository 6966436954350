import { Box, Typography } from '@mui/material'
import React from 'react'
import { Navigate, useRouteError } from 'react-router'
import isDev from '~/config/isDev'

export default function Error({ children }) {
  const error = useRouteError()

  if (error.status === 404) return <Navigate to="/" />

  return (
    <>
      <Box sx={{ borderBottom: '1px solid #E0E0E0', pt: 8, pb: 3, mb: 3 }}>
        <Typography variant="h3" gutterBottom>
          This section is under development
        </Typography>
        <Typography
          sx={{
            color: 'rgba(0, 0, 0, 0.54)',
          }}
        >
          {error.statusText || error.message}
        </Typography>
      </Box>
      {children && <Typography>{children}</Typography>}
    </>
  )
}
