import { styled } from '@mui/material/styles'

const ActionButtons = styled('div')(({ theme }) => ({
  '&:empty': { display: 'none' },
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  // alignItems: 'flex-start',
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
  [theme.breakpoints.up('md')]: {
    '& button': {
      whiteSpace: 'nowrap',
      flexShrink: 0,
    },
  },
}))

export default ActionButtons

// export default props =>
//   props.children?.length ? <ActionButtonsRoot {...props} /> : null
