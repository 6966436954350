import { Svg, Path } from '@react-pdf/renderer'

export default function UnicoPogoVerticalPdf({ ...props }) {
  return (
    <Svg
      width="16"
      height="87"
      viewBox="0 0 16 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M0.0116494 38.4321C0.0116494 39.4847 0.864604 40.5948 1.91731 40.5948L12.0695 40.5948C13.7733 40.5521 13.6814 40.8269 13.6809 42.2061L13.6809 54.0224L13.6809 53.9305L13.6809 66.1523C13.6814 67.5311 13.7733 67.8063 12.0695 67.7636L1.91688 67.7636C0.864604 67.7636 0.0112176 68.874 0.0112176 69.9262L0 86.8387L1.48199 86.8387L1.48199 71.167C1.46617 69.9073 2.10154 69.2719 3.38809 69.261L13.1295 69.261C14.1823 69.261 15.0356 68.4081 15.0356 67.3563L15.0356 41.0033C15.0356 39.9507 14.1818 39.0973 13.1295 39.0973L3.38809 39.0973C2.10154 39.0873 1.46617 38.4524 1.48199 37.1927L1.46991 0.179543L1.0334e-06 0.179543L0.0116494 38.433L0.0116494 38.4321Z" />
      <Path d="M2.91943 86.8367L4.34879 86.8367L4.34879 85.3021L12.3416 85.3021L12.3416 83.105L4.34879 83.105L4.34879 81.57L2.91943 81.57L2.91943 86.8367Z" />
      <Path d="M12.3411 81.0943L2.91895 81.0943L2.91895 79.0033L5.71122 79.0033L5.71122 78.9765C5.04939 78.7651 4.67922 78.2625 4.67922 77.4955C4.67922 76.4359 5.37987 76.0399 6.47832 76.0399L12.3411 76.0399L12.3411 78.1301L7.21996 78.1301C6.80966 78.1301 6.39936 78.1435 6.39936 78.5667C6.39936 78.9899 6.80966 79.0037 7.21996 79.0037L12.3411 79.0037L12.3411 81.0947L12.3411 81.0943Z" />
      <Path d="M8.79182 73.3668L10.3273 73.3668C10.7773 73.3668 11.0547 73.208 11.0547 72.9298C11.0547 72.5993 10.7769 72.4932 10.3273 72.4932L9.34795 72.4932L9.34795 70.4022L10.4589 70.4022C11.8887 70.4022 12.4314 71.4608 12.4314 72.9293C12.4314 74.2658 11.8222 75.4574 10.4196 75.4574L6.8072 75.4574C5.44386 75.4574 4.67676 74.557 4.67676 72.9293C4.67676 71.5933 5.17895 70.4022 6.8072 70.4022L8.79182 70.4022L8.79182 73.3664L8.79182 73.3668ZM7.53461 72.4932L6.50261 72.4932C6.21139 72.4932 6.05262 72.6386 6.05262 72.9298C6.05262 73.221 6.21139 73.3668 6.50261 73.3668L7.53461 73.3668L7.53461 72.4932Z" />
      <Path d="M9.655 61.1916C11.653 61.2447 12.5262 61.9323 12.5262 63.9837C12.5262 66.6964 10.6339 66.749 9.44316 66.749L2.91895 66.749L2.91895 64.5523L9.70807 64.5523C10.4622 64.5523 11.0179 64.5523 11.0179 63.9833C11.0179 63.4142 10.4622 63.3875 9.70807 63.3875L2.91895 63.3875L2.91895 61.1908L9.655 61.1908L9.655 61.1916Z" />
      <Path d="M5.70876 58.52L5.70876 58.4937C5.04736 58.2949 4.67676 57.7923 4.67676 56.9717C4.67676 56.0321 5.32521 55.5554 6.41028 55.5554L12.3387 55.5554L12.3387 57.6464L7.08591 57.6464C6.75414 57.6464 6.39734 57.6728 6.39734 58.0701C6.39734 58.4674 6.75414 58.52 7.08591 58.52L12.3387 58.52L12.3387 60.6111L4.76909 60.6111L4.76909 58.52L5.70876 58.52Z" />
      <Path d="M2.91895 54.9607L2.91895 52.8697L4.25554 52.8697L4.25554 54.9607L2.91895 54.9607ZM4.77241 54.9607L4.77241 52.8697L12.342 52.8697L12.342 54.9607L4.77198 54.9607L4.77241 54.9607Z" />
      <Path d="M6.58199 49.3112C6.30415 49.3112 6.05262 49.4178 6.05262 49.7483C6.05262 50.0787 6.30415 50.1848 6.58199 50.1848L10.5258 50.1848C10.8036 50.1848 11.0552 50.0792 11.0552 49.7483C11.0552 49.4174 10.8036 49.3112 10.5258 49.3112L9.34795 49.3112L9.34795 47.2202L10.4598 47.2202C11.8887 47.2202 12.4314 48.2789 12.4314 49.7608C12.4314 51.3229 11.7834 52.275 10.3277 52.275L6.79383 52.275C5.55042 52.275 4.67676 51.3488 4.67676 49.7608C4.67676 48.4113 5.17938 47.2202 6.80807 47.2202L7.44271 47.2202L7.44271 49.3112L6.58242 49.3112L6.58199 49.3112Z" />
      <Path d="M10.4201 41.5823C11.8222 41.5823 12.4314 42.7734 12.4314 44.1095C12.4314 45.4456 11.8222 46.6376 10.4201 46.6376L6.8072 46.6376C5.44429 46.6376 4.67676 45.7372 4.67676 44.1095C4.67676 42.4818 5.44429 41.5823 6.8072 41.5823L10.4201 41.5823ZM10.5258 44.5465C10.8036 44.5465 11.0552 44.44 11.0552 44.1095C11.0552 43.7791 10.8036 43.6729 10.5258 43.6729L6.58199 43.6729C6.30415 43.6729 6.05262 43.7786 6.05262 44.1095C6.05262 44.4404 6.30415 44.5465 6.58199 44.5465L10.5258 44.5465Z" />
      <Path d="M6.29174 34.5699L4.96851 34.5699C4.50515 34.5699 4.24024 34.7152 4.24024 35.1523C4.24024 35.629 4.50515 35.7347 4.96851 35.7347C6.55621 35.7347 7.02 32.3736 9.93092 32.3736C11.7046 32.3736 12.5247 33.3261 12.5247 35.1652C12.5247 36.5681 12.0489 37.9314 10.421 37.9314L8.67414 37.9314L8.67414 35.7347L10.302 35.7347C10.8577 35.7347 11.0169 35.5625 11.0169 35.1523C11.0169 34.7951 10.8577 34.5699 10.302 34.5699C8.17152 34.5699 8.13139 37.931 5.08716 37.931C3.38039 37.931 2.73193 36.8059 2.73193 35.1121C2.73193 33.6303 3.23542 32.3736 4.81017 32.3736L6.29217 32.3736L6.29217 34.5703L6.29174 34.5699Z" />
      <Path d="M4.77246 29.8064L9.99934 29.1049L9.99934 29.0782L4.77246 28.3776L4.77246 26.6174L12.7524 28.3107C13.6126 28.4833 14.0363 29.0916 14.0363 30.0049L14.0363 31.6861L12.66 31.6861L12.66 30.6006C12.66 30.3228 12.5932 30.2305 12.4478 30.2305C12.3421 30.2305 12.2489 30.2568 12.1164 30.2835L4.77246 31.9242L4.77246 29.8073L4.77246 29.8064Z" />
      <Path d="M7.44271 23.1897L6.62212 23.1897C6.30501 23.1897 6.05305 23.2963 6.05305 23.6267C6.05305 23.9572 6.30458 24.0633 6.62212 24.0633C7.91945 24.0633 7.68087 21.1384 10.1422 21.1384C11.6376 21.1384 12.4319 22.0387 12.4319 23.7855C12.4319 25.122 11.9159 26.1539 10.5525 26.1539L9.34838 26.1539L9.34838 24.0629L10.4861 24.0629C10.8041 24.0629 11.0556 23.9572 11.0556 23.6263C11.0556 23.2954 10.8041 23.1893 10.4861 23.1893C8.68698 23.1893 9.24267 26.1535 6.78132 26.1535C5.27214 26.1535 4.67676 25.0814 4.67676 23.7053C4.67676 22.1172 5.29846 21.0982 6.56905 21.0982L7.44271 21.0982L7.44271 23.1893L7.44271 23.1897Z" />
      <Path d="M6.14589 20.7018L4.76917 20.7018L4.76917 20.0003L3.40625 20.0003L3.40625 17.9101L4.76917 17.9101L4.76917 16.9964L6.14589 16.9964L6.14589 17.9101L10.2743 17.9101C10.7247 17.9101 10.9629 17.8968 10.9629 17.4067L10.9629 16.9964L12.3383 16.9964L12.3383 18.7031C12.3383 19.7484 11.7567 20.0003 10.7636 20.0003L6.14589 20.0003L6.14589 20.7018Z" />
      <Path d="M8.79182 14.5095L10.3273 14.5095C10.7773 14.5095 11.0547 14.3507 11.0547 14.0725C11.0547 13.742 10.7769 13.6359 10.3273 13.6359L9.34795 13.6359L9.34795 11.5449L10.4589 11.5449C11.8887 11.5449 12.4314 12.6031 12.4314 14.072C12.4314 15.4086 11.8222 16.5992 10.4196 16.5992L6.8072 16.5992C5.44386 16.5992 4.67676 15.6998 4.67676 14.072C4.67676 12.7355 5.17895 11.5449 6.8072 11.5449L8.79182 11.5449L8.79182 14.5091L8.79182 14.5095ZM7.53461 13.6359L6.50261 13.6359C6.21139 13.6359 6.05262 13.7813 6.05262 14.0725C6.05262 14.3637 6.21139 14.5095 6.50261 14.5095L7.53461 14.5095L7.53461 13.6359Z" />
      <Path d="M5.77434 8.925L5.77434 8.89868C5.07282 8.68643 4.67676 8.21058 4.67676 7.44311C4.67676 6.67563 5.04693 6.22567 5.77434 6.08029L5.77434 6.05354C5.09957 5.93447 4.67676 5.35207 4.67676 4.61134C4.67676 3.56604 5.35153 3.23515 6.27696 3.23515L12.3383 3.23515L12.3383 5.24551L7.08462 5.24551C6.75327 5.24551 6.39604 5.27269 6.39604 5.66916C6.39604 6.06562 6.75327 6.07985 7.08462 6.07985L12.3383 6.07985L12.3383 8.09022L7.08462 8.09022C6.75327 8.09022 6.39604 8.11697 6.39604 8.51386C6.39604 8.91076 6.75327 8.92413 7.08462 8.92413L12.3383 8.92413L12.3383 10.9358L4.76865 10.9358L4.76865 8.92413L5.77477 8.92413L5.77434 8.925Z" />
      <Path d="M5.7842 2.60388C5.05205 2.60388 4.4502 2.02105 4.4502 1.30189C4.4502 0.582735 5.0486 -0.000100129 5.7842 -0.00010012C6.51981 -0.000100111 7.11045 0.578421 7.11045 1.30189C7.11045 2.02537 6.51161 2.60388 5.7842 2.60388ZM6.96462 1.30189C6.96462 0.695333 6.40332 0.178072 5.7842 0.178072C5.16509 0.178072 4.59516 0.691019 4.59516 1.30189C4.59516 1.91277 5.15689 2.42571 5.7842 2.42571C6.41152 2.42571 6.96462 1.90414 6.96462 1.30189ZM5.03177 1.92053L5.03177 1.26565C5.03177 1.03485 5.13748 0.808791 5.37563 0.808791C5.58574 0.808791 5.73114 0.942529 5.78852 1.12803L6.0957 0.901977C6.2411 0.792399 6.38261 0.662973 6.42316 0.586183L6.45552 0.586183L6.45552 0.796279C6.45552 0.930878 6.22859 1.0314 5.82044 1.32993L5.82044 1.5081L6.17638 1.5081C6.36233 1.5081 6.35327 1.5163 6.37441 1.31397L6.45552 1.31397L6.45552 1.92053L6.37441 1.92053C6.35327 1.71863 6.36233 1.7264 6.17638 1.7264L5.30272 1.7264C5.10857 1.7264 5.12065 1.71863 5.10081 1.92053L5.03134 1.92053L5.03177 1.92053ZM5.73977 1.50853L5.73977 1.3226C5.73977 1.12847 5.6263 1.02709 5.39634 1.02709C5.21816 1.02709 5.11289 1.12372 5.11289 1.33856L5.11289 1.50853L5.73977 1.50853Z" />
    </Svg>
  )
}
