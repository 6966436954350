import { ExpandMore } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Link as MuiLink,
  Typography,
} from '@mui/material'
import React from 'react'
import { useSessionStorage } from 'usehooks-ts'
import ExpandableAlert from '~/routes/CompleteSetup/ExpandableAlert'
import useCompleteSetup from '~/routes/CompleteSetup/useCompleteSetup'
import useCompleteSetupScope from '~/routes/CompleteSetup/useCompleteSetupScope'

const defaultMessage =
  'Your admin still has some things to set up before you can start creating successful proposals. Please open the help page to assist your admin in completing the set up.'

export default function CompleteSetupAdmin({
  sx,
  message = defaultMessage,
  ...rest
}) {
  const [dismissed, setDismissed] = useSessionStorage('dismissed', false)
  if (dismissed) return null
  return (
    <ExpandableAlert
      title="Your admin has not completed account setup"
      onClose={() => setDismissed(true)}
      {...rest}
    >
      <Typography>{message}</Typography>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, mt: 3 }}
      >
        <Button
          size="small"
          variant="outlined"
          color="warning"
          onClick={() => setDismissed(true)}
        >
          Don't show again
        </Button>
        <Button
          size="small"
          color="inherit"
          variant="text"
          as="a"
          target="_blank"
          href="https://unicosalesapp.zendesk.com/hc/en-us"
        >
          Help page
        </Button>
        {/* <MuiLink color="inherit" onClick={() => setDismissed(true)}>
          Don't show again
        </MuiLink>
        <MuiLink size="small" color="inherit" variant="text">
          Help page
        </MuiLink> */}
      </Box>
    </ExpandableAlert>
  )
}

export function CompleteSetupAdminByLocation(props) {
  const scopeItemsByLocation = useCompleteSetupScope()

  if (!scopeItemsByLocation?.some(({ admin }) => admin)) return null

  return <CompleteSetupAdmin {...props} />
}

export function CompleteSetupAdminAll(props) {
  const scopes = useCompleteSetup()

  console.log('SCOPES', scopes)

  if (!scopes.some(({ items }) => items.some(({ admin }) => admin))) return null

  return <CompleteSetupAdmin {...props} />
}
