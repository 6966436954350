import { View, StyleSheet, Text } from '@react-pdf/renderer'

const tableStyles = StyleSheet.create({
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    borderBottom: '1px solid #aaa',
  },
  td: {
    padding: 5,
  },
  th: {
    padding: 5,
    fontWeight: 'bold',
  },
})

export default function Table({ columns, data, idKey = 'id' }) {
  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
        {columns.map(({ name, title, width = `${100 / columns.length}%` }) => (
          <View key={name} style={[tableStyles.th, { width }]}>
            <Text>{title || ''}</Text>
          </View>
        ))}
      </View>
      {data.map((row, i) => (
        <View key={row[idKey] || i} style={tableStyles.row} wrap={false}>
          {columns.map(({ name, width = `${100 / columns.length}%` }) => (
            <View style={[tableStyles.td, { width }]}>
              <Text>{row[name]}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  )
}
