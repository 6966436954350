import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import { camelToSentence } from '~/core/utils/formatString'
import sxCardInfo from '~/routes/Proposals/Overview/Summary/sxCardInfo'

const renderObject = o =>
  o &&
  Object.entries(o)
    .filter(
      ([key, value]) => value && ['string', 'number'].includes(typeof value)
    )
    .map(([key, value]) => (
      <React.Fragment key={key}>
        <Typography variant="h6">{camelToSentence(key)}</Typography>
        <div>{camelToSentence(value)}</div>
      </React.Fragment>
    ))

const OverviewSystemSteps = ({ data, index }) =>
  data?.contents?.systems?.map(
    ({ configuration, duct, equipment }, i, { length }) => (
      <Card sx={{ p: 2 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          System {length > 1 ? `${i + 1}` : ''} steps
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
            gap: 2,
            gridAutoFlow: 'row',
          }}
        >
          {renderObject(configuration) ||
            'System steps will begin to show after step 4, Configuration, has been completed'}
          {renderObject(duct)}
          {renderObject(equipment)}
        </Box>
      </Card>
    )
  ) || null

export default OverviewSystemSteps
