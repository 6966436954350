import { Image, Page as PdfPage, Text, View } from '@react-pdf/renderer'
import {
  IconCompactConfiguration,
  IconEvenTemperatures,
  IconIndoorAirQuality,
  IconLowImpactInstallation,
  IconQuietPerformance,
} from './FooterIcons'
import styles from './styles'
import UnicoPogoPdf from '../UnicoLogoPdf'
import UnicoPogoVerticalPdf from '../UnicoLogoVerticalPdf'
const CDN_DOMAIN = import.meta.env.VITE_CDN_DOMAIN

const { b, h1, h2, a } = styles

const PageTemplate = ({ children, ...props }) => (
  <PdfPage size="A4" style={styles.page} {...props}>
    <PageHeader {...props} />
    <View style={styles.pageContent}>{children}</View>
    <PageFooter />
  </PdfPage>
)

function PageHeader({ proposal }) {
  const logo = proposal?.contents?.company?.logo
  console.log(`${CDN_DOMAIN}${logo}`)
  return (
    <View fixed style={styles.pageHeader}>
      <UnicoPogoPdf width={120} />
      {['.png', '.jpg', '.jpeg'].some(ext => logo?.endsWith(ext)) && (
        <Image
          src={`${CDN_DOMAIN}${logo}`}
          // width={100}
          // height={60}
          style={{
            width: 140,
            height: 70,
            objectFit: 'contain',
            objectPosition: 'right top',
          }}
        />
      )}
      {/* <UnicoPogoPdf /> */}
    </View>
  )
}

const PageFooter = () => (
  <View fixed style={styles.pageFooter}>
    <View style={styles.iconGrid}>
      <View>
        <UnicoPogoVerticalPdf style={{ maxHeight: 70 }} />
      </View>
      <View style={styles.iconItem}>
        <IconCompactConfiguration style={styles.iconSvg} />
        <Text style={styles.iconText}>Compact</Text>
        <Text style={styles.iconText}>Configuration</Text>
      </View>
      <View style={styles.iconItem}>
        <IconEvenTemperatures style={styles.iconSvg} />
        <Text style={styles.iconText}>Even</Text>
        <Text style={styles.iconText}>Temperatures</Text>
      </View>
      <View style={styles.iconItem}>
        <IconLowImpactInstallation style={styles.iconSvg} />
        <Text style={styles.iconText}>Low Impact</Text>
        <Text style={styles.iconText}>Installation</Text>
      </View>
      <View style={styles.iconItem}>
        <IconQuietPerformance style={styles.iconSvg} />
        <Text style={styles.iconText}>Quiet</Text>
        <Text style={styles.iconText}>Performance</Text>
      </View>
      <View style={styles.iconItem}>
        <IconIndoorAirQuality style={styles.iconSvg} />
        <Text style={styles.iconText}>Indoor</Text>
        <Text style={styles.iconText}>Air Quality</Text>
      </View>
    </View>
  </View>
)

export default PageTemplate
