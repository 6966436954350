import { Box, Button, Card, Stack, TableCell } from '@mui/material'
import AddCompanyForm from '~/components/Companies/AddCompanyForm'
import EditCompanyForm from '~/components/Companies/EditCompanyForm'
import Drawer from '~/components/Layouts/Drawer'
import PageHeader from '~/components/Layouts/SalesApp/PageHeader'
import Link from '~/config/Link'
import { useParam } from '~/core/router/useParams'
// import AddCompanyForm from './AddCompanyForm'
import { Discount, DomainAdd, LinkOff, People } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import React from 'react'
import client from '~/client'
import AutoTable, {
  AddressCell,
  ChipCell,
} from '~/components/Data/AutoTable/AutoTable'
import { useContractorNetworkCompanies } from '~/components/Companies/useNetworkCompanies'
import SubmitButton from '~/components/Data/SubmitButton'
import { sxAppStylesFlex } from '~/components/Layouts/AppStyles'
import sharedProps from '~/components/sharedProps'
import CreatePairingModal from '../CreatePairingModal'
import AutoSearchTextField from '~/components/Data/AutoTable/AutoSearchTextField'
import ActionButtons from '~/components/Layouts/SalesApp/ActionButtons'
import CompleteSetupByLocation from '~/routes/CompleteSetup/CompleteSetupByLocation'
import { CompleteSetupAdminByLocation } from '~/routes/CompleteSetup/CompleteSetupAdmin'

export default function ContractorNetworkContractingFirms({}) {
  const [search, setSearch] = React.useState()

  const {
    data,
    updateRelationDiscount,
    isUpdatingDiscount,
    unpairRelation,
    isUnpairing,
    ...response
  } = useContractorNetworkCompanies({ search })

  return (
    <>
      <ActionButtons>
        <CompleteSetupAdminByLocation />
        <CompleteSetupByLocation sx={{ mb: 2 }} />

        {client.hasRoles(['distributor_admin', 'distributor_user']) && (
          <CreatePairingModal mode="contractingFirm" />
        )}
        <Link to="?add">
          <Button variant="contained" endIcon={<DomainAdd />}>
            Add new company
          </Button>
        </Link>
      </ActionButtons>

      <Card sx={sxAppStylesFlex}>
        <AutoTable
          appStyles
          setSearch={setSearch}
          {...response}
          data={data}
          titleField="companyName"
          uniqueField="companyId"
          columns={[
            'companyName',
            { field: 'companyType', Component: ChipCell },
            {
              field: 'address.$-zipCode.string_contains',
              headerSearch: <AutoSearchTextField placeholder="ZIP code" />,
              headerName: 'Address',
              Component: AddressCell,
            },
            { field: 'discount' },
            {
              Component: ({ data }) => (
                <TableCell sx={{ px: '0 !important' }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Link
                      to={`../contracting-firm-users/${data.companyId}`}
                      state={data}
                    >
                      <Button
                        {...sharedProps.button.table}
                        startIcon={<People />}
                      >
                        Users
                      </Button>
                    </Link>
                    {client.hasRoles([
                      'distributor_admin',
                      'distributor_user',
                    ]) && (
                      <>
                        <SubmitButton
                          isSubmitting={isUpdatingDiscount === data.relationId}
                          type="button"
                          {...sharedProps.button.table}
                          endIcon={<Discount />}
                          onClick={() => updateRelationDiscount(data)}
                          // onClick={() => console.log('unpairRelation', data)}
                        >
                          Edit&nbsp;discount
                        </SubmitButton>
                        <SubmitButton
                          isSubmitting={isUnpairing === data.relationId}
                          type="button"
                          {...sharedProps.button.table}
                          endIcon={<LinkOff />}
                          color="error"
                          onClick={() =>
                            confirm(
                              `Are you sure you want to unpair from ${
                                data.companyName || 'this company'
                              }? You will not be able to pair again.`
                            ) && unpairRelation(data)
                          }
                        >
                          Unpair
                        </SubmitButton>
                      </>
                    )}
                  </Box>
                </TableCell>
              ),
            },
          ]}
        />
      </Card>
      <Drawer open={useParam('add')}>
        <AddCompanyForm />
      </Drawer>
      <Drawer open={useParam('edit')}>
        <EditCompanyForm />
      </Drawer>
    </>
  )
}
