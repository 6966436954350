const UnicoU = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    fill="currentColor"
    {...props}
  >
    <path d="M16.99 15.47c-.1 3.59-1.33 5.16-5.01 5.16-4.87 0-4.96-3.4-4.96-5.54V3.37h3.94v12.19c0 1.35 0 2.35 1.02 2.35s1.07-1 1.07-2.35V3.37h3.94z" />
  </svg>
)

export default UnicoU
