import { Card, Link, Typography } from '@mui/material'
import React from 'react'
import client from '~/client'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import useCompanyDetails from '~/routes/Company/Profile/useCompanyDetails'
import addressToArray from '~/routes/ViewProposal/addressToArray'

export default function RelatedRepCompanyInfo() {
  const { data: usersCompany, isLoading: isLoadingUserCompany } =
    useCompanyDetails()

  const partialRepCompany = usersCompany?.repCompanyContractingFirms?.find(
    ({ status }) => status === 'active'
  )?.repCompany

  const { data = partialRepCompany, isLoading: isLoadingRepComapny } =
    useCompanyDetails(partialRepCompany)

  if (client.hasOnlyRoles('rep')) return null

  const emailAddress = data?.contactEmails?.generalEnquiries

  const isLoading = isLoadingUserCompany || isLoadingRepComapny

  return (
    <Card sx={{ p: 2, '& p': { mb: 2 } }}>
      <Typography variant="h4" sx={{ mb: 1 }}>
        Your rep company
      </Typography>
      <LoadingIndicators isLoading={isLoading} />
      {isLoading || data?.companyType === 'repCompany' ? (
        <>
          <Typography variant="h6">Company name</Typography>
          <Typography>{data?.companyName || <>&nbsp;</>}</Typography>

          <Typography variant="h6">Email</Typography>
          <Typography>
            <Link href={`mailto:${emailAddress}`}>
              {emailAddress || <>&nbsp;</>}
            </Link>
          </Typography>

          <Typography variant="h6">Phone number</Typography>
          <Typography>{data?.companyName || <>&nbsp;</>}</Typography>

          <Typography variant="h6">Address</Typography>
          <Typography>
            {addressToArray(data?.address).map(line => (
              <div>{line}</div>
            ))}
          </Typography>
        </>
      ) : (
        <Typography>
          You don't currently have a rep company assigned.
        </Typography>
      )}
    </Card>
  )
}
