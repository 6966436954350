import { Box, Button, Card, Typography } from '@mui/material'
import client from '~/client'
import CompaniesTable from '~/components/Companies/CompaniesTable'
import PageHeader from '~/components/Layouts/SalesApp/PageHeader'
import UsersTable from '~/components/Users/UsersTable'
import EditUser from '~/components/Users/EditUser'
import Drawer from '~/components/Layouts/Drawer'
import useSearchParams from '~/core/router/useParams'
import { useNavigate } from 'react-router'
import DeleteUser from '~/components/Users/DeleteUser'
import flexSplitSx from '~/core/sx/flexSplitSx'
import { PersonAdd } from '@mui/icons-material'
import AddNewUser from '~/components/Users/AddNewUser'
import { sxAppStylesFlex } from '~/components/Layouts/AppStyles'
import ActionButtons from '~/components/Layouts/SalesApp/ActionButtons'
import { userRoleOptions } from '~/components/Data/AutoTable/statusOptions'
import isDev from '~/config/isDev'

export default function RepGroupDistributorUsers() {
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()

  return (
    <>
      {/* {client.hasRoles([
        'rep_admin',
        'rep_sales_manager',
        'distributor_user',
        'distributor_admin',
      ]) && (
        <ActionButtons>
          <Button
            variant="contained"
            onClick={() =>
              navigate(`?addUser`, { state: { user: { companyId: '' } } })
            }
            endIcon={<PersonAdd />}
          >
            Add user
          </Button>
        </ActionButtons>
      )} */}

      <Card sx={sxAppStylesFlex}>
        <UsersTable
          canResend={client.hasRoles([
            'unico_admin',
            'rep_admin',
            'rep_sales_manager',
          ])}
          query="repCompanyRelations/distroUsers"
          userRoleOptions={userRoleOptions.filter(({ id }) =>
            ['distributor_user', 'distributor_admin'].includes(id)
          )}
          disableEdit
          // disableEdit={client.hasOnlyRoles(['rep_admin'])}
          // onEditClick={user =>
          //   navigate(`?editUser=${user.userId}`, { state: user })
          // }
          // onDeleteClick={user =>
          //   navigate(`?deleteUser=${user.userId}`, { state: user })
          // }
        />
      </Card>

      {/* <Drawer open={params.hasOwnProperty('addUser')}>
        <AddNewUser companyType="contractingFirm" />
      </Drawer>

      <Drawer open={params.editUser}>
        <EditUser />
      </Drawer>

      <Drawer open={params.deleteUser}>
        <DeleteUser />
      </Drawer> */}
    </>
  )
}
