import {
  AppBar,
  Button,
  Card,
  Container,
  Divider,
  GlobalStyles,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { Box } from '@mui/system'

import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined'
import EngineeringIcon from '@mui/icons-material/Engineering'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import NotesIcon from '@mui/icons-material/Notes'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove'

import React from 'react'
import { Outlet } from 'react-router'

import ReactPlayer from 'react-player/vimeo'
import useMeasure from 'react-use-measure'

import UnicoLogo from '~/components/Layouts/UnicoLogo'
import { formatDate } from '~/core/utils/formatDate'
import { usd } from '~/core/utils/formatNumber'
import AssetList from '~/routes/Company/Assets/AssetList'
import Splash, { LoadingSplash } from '~/routes/ViewProposal/Splash'

import GoogleMap from '~/routes/ViewProposal/GoogleMap'
import TheUnicoDifference from '~/routes/ViewProposal/TheUnicoDifference'

import { AnimatePresence } from 'framer-motion'

import { Download } from '@mui/icons-material'
import MaterialsList from '~/routes/ViewProposal/MaterialsList'
import ProposalStatus from '~/routes/ViewProposal/ProposalStatus'
import StepperHeader from '~/routes/ViewProposal/StepperHeader'
import {
  AlternateSection,
  BlueBox,
  Demarcator,
  Highlight,
  Section,
} from '~/routes/ViewProposal/Subcomponents'
import addressToArray from '~/routes/ViewProposal/addressToArray'
import usePublicProposal from '~/routes/ViewProposal/usePublicProposal'

import { darken, lighten } from '@mui/material'
import HomeownerProposalDownloadPdfButton from '~/routes/ViewProposal/HomeownerProposalDownloadPdfButton'

const CDN_DOMAIN = import.meta.env.VITE_CDN_DOMAIN

const steps = [
  { label: 'Details', name: 'details', Icon: NotesIcon },
  { label: 'Contractor', name: 'contractor', Icon: EngineeringIcon },
  { label: 'Proposal', name: 'proposal', Icon: DescriptionOutlined },
  { label: 'Materials', name: 'materials', Icon: FormatListBulletedIcon },
  { label: 'Assets', name: 'assets', Icon: PhotoLibraryIcon },
]

const Icon = ({ name, sx, ...rest }) => {
  const Component = steps.find(step => step.name === name)?.Icon || NotesIcon
  return (
    <Component
      sx={{ fontSize: 32, color: 'var(--primary-color, #fff)', ...sx }}
      {...rest}
    />
  )
}

const alternateStyle = ({ primaryColor, secondaryColor } = {}) => ({
  '--primary-color': primaryColor || '#1d589a',
  '--primary-color-light': lighten(primaryColor || '#1d589a', 0.12),
  '--primary-color-dark': darken(primaryColor || '#1d589a', 0.12),
  '--secondary-color': secondaryColor || '#C9321F',
  '--section-background': primaryColor ? '#F8F8F8' : '#f9f9fc',
  '--section-text': primaryColor ? 'black' : '#18203B',
  '--alternate-background': primaryColor ? 'white' : '#18203B',
  '--alternate-text': primaryColor ? 'black' : 'white',
  '--feature-background': primaryColor ? '#ffffff' : '#1D589A',
  '--feature-text': primaryColor ? 'black' : 'white',
})

const useAlternateStyle = ({ primaryColor, secondaryColor } = {}) => {
  const theme = useTheme()
  if (!primaryColor) return
  try {
    const contrastText =
      secondaryColor || theme.palette.getContrastText(primaryColor)
    const style = alternateStyle({ primaryColor, secondaryColor })
    return style
  } catch (e) {
    console.log(e)
    return
  }
}

export default function ViewProposal({
  proposalId: propsProposalId,
  disableSplash,
  proposal: propsProposal,
}) {
  const {
    proposal = propsProposal,
    isLoading,
    mutate,
    updateStatus,
    submitting,
  } = usePublicProposal(propsProposalId)

  const {
    customer,
    contractor,
    company,
    lineItems,
    totalPrice,
    details,
    // isDraft,
    // template,
    systems,
    companyId,
    project,
  } = proposal?.contents || {}

  const template = project?.template
  const assets = project?.companyAssets

  console.log('proposal data', proposal)

  const [splash, setSplash] = React.useState(disableSplash ?? null)

  const [headerRef, { height }] = useMeasure()
  const HashAnchor = ({ ...props }) => (
    <div {...props} style={{ position: 'relative', top: -height + 1 }} />
  )

  React.useEffect(() => void !disableSplash && window.scroll({ top: 0 }), [])

  React.useEffect(() => {
    if (
      typeof proposal?.status === 'string' &&
      proposal.status === 'sent' &&
      !disableSplash
    )
      setSplash(true)
    else if (!isLoading) setSplash(false)
  }, [proposal?.status])

  const imageAssets = assets?.filter(asset =>
    asset.meta.ContentType.startsWith('image')
  )

  const documentAssets = assets?.filter(
    asset => !asset.meta.ContentType.startsWith('image')
  )

  const visibleSteps = assets?.length
    ? steps
    : steps.filter(step => step.name !== 'assets')

  // if (company) company.primaryColor = '#1d589a'

  return (
    <>
      <GlobalStyles
        styles={{
          body: splash || isLoading ? { overflow: 'hidden' } : {},
          '#root > main': { padding: 0 },
          // TODO: remove paddingTop from parent layout
          'p.MuiTypography-body1': {
            fontSize: 16,
          },
          'h2.MuiTypography-h2': {
            fontWeight: 300,
          },
          'h3.MuiTypography-h3': {
            fontWeight: 300,
          },
          ':root': company?.primaryColor ? alternateStyle(company) : {},
        }}
      />
      <AnimatePresence>
        {isLoading && <LoadingSplash key="loading" isLoading={isLoading} />}
        {splash && !disableSplash && (
          <Splash
            key="splash"
            splash={splash}
            firstName={customer?.name}
            onClick={() => setSplash(false)}
          />
        )}
      </AnimatePresence>
      <Box
        ref={headerRef}
        sx={theme => ({
          position: disableSplash ? 'static' : 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: theme.zIndex.appBar,
        })}
      >
        <AppBar
          position="static"
          sx={{
            background: 'var(--feature-background, #1d589a)',
            color: 'var(--feature-text, #fff)',
            transition: 'all 0.3s',
            '@media print': {
              background: 'transparent',
              color: '#000',
              boxShadow: 'none',
            },
          }}
        >
          <Container sx={{ py: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={3}>
                <UnicoLogo
                  width="156px"
                  height="auto"
                  style={{ maxWidth: '100%' }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: { xs: 'none', md: 'flex', justifyContent: 'center' },
                }}
              >
                <StepperHeader
                  steps={visibleSteps}
                  sx={{ '& a:last-child': { paddingRight: 0 } }}
                />
              </Grid>

              <Grid
                item
                xs={6}
                md={3}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {company?.logo && (
                  <Box
                    as="img"
                    sx={{
                      width: 156,
                      maxWidth: '100%',
                      maxHeight: 80,
                      objectFit: 'contain',
                      objectPosition: 'center right',
                    }}
                    src={`${CDN_DOMAIN}${company.logo}`}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
        </AppBar>
        <ProposalStatus
          updateStatus={updateStatus}
          // status={proposal?.status}
          status="sent"
          secretKey={proposal?.secretKey}
        />
      </Box>

      <Box sx={{ height }} />

      <HashAnchor id="details" />
      <AlternateSection>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2" sx={{ display: 'flex', mb: 8 }}>
              <Icon name="details" sx={{ mr: 2 }} />
              Your details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Highlight />
            <Typography variant="h3" gutterBottom>
              Contact info
            </Typography>
            {[customer?.name, customer?.email].map(text => (
              <Typography sx={{ wordWrap: 'break-word' }}>{text}</Typography>
            ))}
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Highlight />
            <Typography variant="h3" gutterBottom>
              Address
            </Typography>
            {addressToArray(customer?.address).map(text => (
              <Typography sx={{ wordWrap: 'break-word' }}>{text}</Typography>
            ))}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <GoogleMap
              q={addressToArray(customer?.address).join(',')}
              sx={{ borderRadius: 0.5 }}
            />
          </Grid>
        </Grid>
      </AlternateSection>
      <Demarcator />

      <HashAnchor id="contractor" />
      <Section>
        <Grid container spacing={3} sx={{ mb: 6 }}>
          <Grid item xs={12}>
            <Typography variant="h2" sx={{ display: 'flex', mb: 8 }}>
              <Icon name="contractor" sx={{ mr: 2 }} />A note from your
              contractor
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Highlight />
            <Typography variant="h3" gutterBottom>
              Name
            </Typography>
            {[
              `${contractor?.firstName} ${contractor?.lastName}`,
              contractor?.phoneNumber,
              contractor?.email,
            ]
              .filter(v => v)
              .map(text => (
                <Typography sx={{ wordWrap: 'break-word' }}>{text}</Typography>
              ))}
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Highlight />
            <Typography variant="h3" gutterBottom>
              Company
            </Typography>
            {[
              company?.companyName,
              company?.phoneNumber,
              company?.contactEmails?.generalEnquiries,
            ]
              .filter(v => v)
              .map(text => (
                <Typography sx={{ wordWrap: 'break-word' }}>{text}</Typography>
              ))}
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Highlight sx={{ visibility: 'hidden' }} />
            <Typography variant="h3" gutterBottom sx={{ visibility: 'hidden' }}>
              Address
            </Typography>
            {addressToArray(company?.address).map(text => (
              <Typography>{text}</Typography>
            ))}
          </Grid>
        </Grid>

        {template?.contents?.length && (
          <>
            <Typography variant="h3" sx={{ mb: 3 }}>
              A message from your contractor
            </Typography>
            <Card sx={{ maxWidth: 800, p: 3, margin: 'auto' }}>
              {template?.contents?.split('\n').map(p => (
                <Typography paragraph>{p}</Typography>
              ))}
            </Card>
          </>
        )}
      </Section>

      <Demarcator />
      <AlternateSection
        sx={{
          textAlign: 'center',
          '& > div': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          },
        }}
      >
        <Typography variant="h2" sx={{ mb: 2 }}>
          The Unico Difference
        </Typography>
        <Typography sx={{ maxWidth: 400, mb: 6 }}>
          The Unico System provides the comfort and efficiency of central air
          conditioning and heating, without compromising the integrity or design
          of your home.
        </Typography>
        <TheUnicoDifference />
      </AlternateSection>
      <Demarcator />

      <HashAnchor id="proposal" />
      <Section>
        <Box sx={{ textAlign: 'center', mb: 5 }}>
          <Icon name="proposal" />
          <Typography variant="h2">What you're really here for</Typography>
        </Box>
        <Stack spacing={8}>
          <div>
            <Highlight />
            <Typography variant="h3" sx={{ mb: 3 }}>
              Your new HVAC system
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Everything you need to know about getting a HVAC system installed!
            </Typography>
          </div>
          <div>
            <Highlight />
            <Typography variant="h3" sx={{ mb: 3 }}>
              Your installation timings
            </Typography>
            <Stack direction="row" spacing={3}>
              {[
                {
                  title: 'Estimated start date',
                  value: formatDate(project?.startDate),
                },
                {
                  title: 'Estimated duration',
                  value: `${project?.duration} working days`,
                },
              ].map(({ title, value }) => (
                <Box>
                  <Typography gutterBottom>{title}</Typography>
                  <BlueBox>{value}</BlueBox>
                </Box>
              ))}
            </Stack>
          </div>
          <div>
            <Highlight />
            <Typography variant="h3" sx={{ mb: 3 }}>
              Your payment breakdown
            </Typography>
            <Card sx={{ p: { xs: 2, sm: 3, md: 6 } }}>
              {project?.paymentBreakdown?.map(
                ({ percentage, date, description, title = description }, i) => (
                  <>
                    <Grid container spacing={4}>
                      <Grid item xs={6} md={3}>
                        <Typography variant="h4" gutterBottom>
                          Payment {i + 1}
                        </Typography>
                        <BlueBox
                          sx={{
                            // background: 'var(--alternate-background)', ///////
                            borderBottom:
                              '2px solid var(--primary-color, transparent)', ///////
                            // color: 'inherit',
                          }}
                        >
                          {usd.format((percentage / 100) * totalPrice)}
                        </BlueBox>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="h4" gutterBottom>
                          Due date
                        </Typography>
                        <BlueBox
                          sx={{
                            // background: 'var(--alternate-background)', ///////
                            borderBottom:
                              '2px solid var(--primary-color, transparent)', ///////
                            // color: 'inherit',
                          }}
                        >
                          {formatDate(date)}
                        </BlueBox>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h4" gutterBottom>
                          What is this payment
                        </Typography>
                        <Typography>{title}</Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 4 }} />
                  </>
                )
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography variant="h4" gutterBottom>
                  Total investment
                </Typography>
                <BlueBox
                  sx={theme => ({
                    background: 'var(--feature-background, #18203B)', ///////
                    borderBottom: '2px solid var(--primary-color, transparent)', ///////
                    // color: 'inherit',
                  })}
                >
                  {usd.format(totalPrice)}
                </BlueBox>
              </Box>
            </Card>
          </div>
        </Stack>
      </Section>

      {company?.primaryColor && <Demarcator />}
      <AlternateSection sx={{ textAlign: 'center' }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Thank you for your consideration
        </Typography>
        <Typography>
          We appreciate you considering Unico as a choice for your HVAC
          provider.
        </Typography>
        <Typography sx={{ mb: 6 }}>
          Shannon from Unico has a few words to share...
        </Typography>
        <Box
          sx={{
            maxWidth: '780px',
            aspectRatio: '16/9',
            background: '#000',
            borderRadius: 2,
            margin: 'auto',
          }}
        >
          <ReactPlayer
            url="https://player.vimeo.com/video/901466014?badge=0&autopause=0&player_id=0&app_id=58479"
            width="100%"
            height="100%"
            controls
          />
        </Box>
      </AlternateSection>
      {company?.primaryColor && <Demarcator />}

      <HashAnchor id="materials" />
      {project?.includeMaterialsList && (
        <Section>
          <Box sx={{ textAlign: 'center', mb: 5 }}>
            <Icon name="materials" />
            <Typography variant="h2">Equipment breakdown</Typography>
          </Box>
          <MaterialsList lineItems={lineItems} />
        </Section>
      )}

      <HashAnchor id="assets" />
      {(imageAssets || documentAssets) && (
        <AlternateSection>
          <Box sx={{ textAlign: 'center', mb: 5 }}>
            <Icon name="assets" />
            <Typography variant="h2">And all the rest</Typography>
          </Box>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h4" gutterBottom>
              Your assets
            </Typography>
            <Highlight sx={{ width: '100%' }} />
          </Box>
          {imageAssets?.length && (
            <Box sx={{ mb: 4 }}>
              {/* <Highlight /> */}
              {/* <Typography variant="h4">Assets</Typography> */}
              <Grid item xs={12} lg={4}>
                <AssetList
                  assets={imageAssets}
                  sx={{
                    gridTemplateColumns: {
                      xs: 'repeat(2, 1fr) !important',
                      sm: 'repeat(3, 1fr) !important',
                      md: 'repeat(4, 1fr) !important',
                      lg: 'repeat(6, 1fr) !important',
                    },
                  }}
                />
              </Grid>
            </Box>
          )}
          {documentAssets?.length > 0 && (
            <Box sx={{ mb: 4 }}>
              {/* <Highlight /> */}
              {/* <Typography variant="h4">Documents</Typography> */}
              <Grid item xs={12} lg={4}>
                <AssetList
                  assets={documentAssets}
                  sx={{
                    gridTemplateColumns: {
                      xs: 'repeat(2, 1fr) !important',
                      sm: 'repeat(4, 1fr) !important',
                      md: 'repeat(6, 1fr) !important',
                      lg: 'repeat(8, 1fr) !important',
                    },
                  }}
                />
              </Grid>
            </Box>
          )}
        </AlternateSection>
      )}

      <HashAnchor id="exclusions" />
      {(project?.exclusionsTemplate || project?.exclusionsTemplateFile) && (
        <Section>
          <Box sx={{ mb: 5 }}>
            {/* <Icon name="materials" /> */}

            <Typography
              variant="h2"
              sx={{
                maxWidth: 800,
                margin: 'auto',
                mb: 3,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PlaylistRemoveIcon sx={{ fontSize: 32, mr: 2 }} />
              Exclusions
            </Typography>
            {project?.exclusionsTemplate && (
              <Card sx={{ maxWidth: 800, p: 3, margin: 'auto' }}>
                {project?.exclusionsTemplate?.split('\n').map(p => (
                  <Typography paragraph>{p}</Typography>
                ))}

                {project?.exclusionsTemplateFile && (
                  <Button
                    // variant="text"
                    href={`${CDN_DOMAIN}${project.exclusionsTemplateFile}`}
                    target="_blank"
                    startIcon={<Download />}
                  >
                    Download project exclusions
                  </Button>
                )}
              </Card>
            )}
          </Box>
        </Section>
      )}

      <AlternateSection>
        <Box sx={{ textAlign: 'center' }}>
          <Typography as="a" href="https://www.unicosystem.com/terms-of-use/">
            Terms & Conditions
          </Typography>
          <div>
            <HomeownerProposalDownloadPdfButton sx={{ mt: 2 }} />
          </div>
        </Box>
      </AlternateSection>

      <Outlet />
    </>
  )
}
