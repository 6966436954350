import { Font, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  unicoLogo: {
    width: 100,
    height: 100,
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 30,
    fontSize: 11,
    fontFamily: 'Nunito',
  },
  section: {
    border: '1px dashed red',
    marginBottom: 10,
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  pageFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 20,
  },
  pageContent: {
    flexGrow: 1,
  },
  iconGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  iconItem: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconText: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  iconSvg: {
    width: 20,
    height: 20,
    marginBottom: 10,
  },
  qrCodes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
    paddingTop: 10,
    borderTop: '1px solid #000',
    borderBottom: '1px solid #000',
    marginBottom: 50,
  },
  b: { fontWeight: 700 },
  h2: { fontSize: 16, marginBottom: 20 },
  h3: { fontSize: 11, fontWeight: 'bold', marginBottom: 10 },
  a: { color: 'inherit', textDecoration: 'none' },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    flexWrap: 'wrap',
  },
  gridItem: {
    padding: 10,
    border: '1px dashed #000',
    width: '33%',
  },
  hr: {
    height: 1,
    borderTop: '1px solid #000',
    marginTop: 20,
    marginBottom: 20,
  },
  bold: { fontWeight: 700 },
})

Font.register({
  family: 'Nunito',
  fonts: [
    { src: '/fonts/Nunito-Regular.ttf' }, // font-style: normal, font-weight: normal
    { src: '/fonts/Nunito-Bold.ttf', fontWeight: 700 },
  ],
})

export default styles
