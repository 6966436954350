import {
  Alert,
  AlertTitle,
  Box,
  Card,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Chip from '~/components/Data/Chip'
import sxfGridColMinWidths from '~/core/sx/sxfGridColMinWidths'
import ExpandableAlert from '~/routes/CompleteSetup/ExpandableAlert'
import useCompleteSetupScope from '~/routes/CompleteSetup/useCompleteSetupScope'

const td = [
  {
    title: 'Create a contracting firm',
    message:
      'Connect with local branches to use their Unico list pricing. Contact them for their PIN to pair.',
  },
  {
    title: 'Pair with your first branch',
    message:
      'Pair with branches in your area to use their products in your proposals. Contact your branches to get their PIN to pair with them.',
  },
  {
    title: 'Create a branch',
    message: 'Create a branch for contractors to buy from',
  },
]

export default function CompleteSetupByLocation({
  pathname: propsPathname,
  sx,
  ...rest
}) {
  const scopeItemsByLocation = useCompleteSetupScope()
  // const scopeItemsByLocation = td //useCompleteSetupScope()

  const isLarge = useMediaQuery(theme => theme.breakpoints.up('xl'))

  if (!scopeItemsByLocation?.length > 0) return null

  if (isLarge)
    return (
      <Card
        sx={{
          p: 2,
          ml: 0,
          mr: 'auto',
          // ...sx
        }}
        {...rest}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ mb: 1 }} variant="h4">
            To do list
          </Typography>
          <Chip label="Not complete" color="error" />
        </Box>
        <Box
          // sx={sxfGridColMinWidths('320px')}
          sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}
        >
          {scopeItemsByLocation.map(({ title, message }) => (
            <Alert icon={false} severity="info" key={title}>
              <AlertTitle>{title}</AlertTitle>
              {message}
            </Alert>
          ))}
        </Box>
      </Card>
    )

  if (scopeItemsByLocation.length === 1)
    return scopeItemsByLocation.map(({ title, message }) => (
      <ExpandableAlert
        // sx={{ mb: 2 }}
        severity="info"
        title={
          <>
            <strong>To do: </strong>
            {title}
          </>
        }
      >
        {message}
      </ExpandableAlert>
    ))

  const title = scopeItemsByLocation.map(({ title }) => title).join(', ')
  const todoTitle = (
    <>
      <strong>To do: </strong>
      {title}
    </>
  )

  return (
    <ExpandableAlert
      // sx={{ mb: 2 }}
      title={todoTitle}
      expandedTitle={<strong>To do: </strong>}
      severity="info"
    >
      {scopeItemsByLocation.map(({ title, message }) => (
        <Alert sx={{ pl: 0 }} key={title} icon={false} severity="info">
          <AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>
          {message}
        </Alert>
      ))}
    </ExpandableAlert>
  )
}
