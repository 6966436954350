import { Link, Text, View } from '@react-pdf/renderer'
import addressToArray from '~/routes/ViewProposal/addressToArray'
import styles from './styles'

const { b, h1, h2, a } = styles

export default function LetterHeader({ proposal }) {
  const company = proposal.contents.company
  const customer = proposal.contents.customer
  const toAddress = addressToArray(customer.address)
  const fromAddress = addressToArray(company.address)
  const companyEmail = company.contactEmails?.generalEnquiries

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
        marginBottom: 50,
      }}
    >
      <View>
        <Text style={b}>{customer.name}</Text>
        {toAddress.map(line => (
          <Text key={line}>{line}</Text>
        ))}
        <Text>&nbsp;</Text>
        <Text>
          <Link style={a} src={`mailto:${customer.email}`}>
            {customer.email}
          </Link>
        </Text>
        <Text>{customer.phoneNumber}</Text>
      </View>

      <View style={{ alignItems: 'flex-end' }}>
        <Text style={b}>{company.companyName}</Text>
        {fromAddress.map(line => (
          <Text key={line}>{line}</Text>
        ))}
        <Text>&nbsp;</Text>
        {companyEmail && (
          <Text>
            <Link style={a} src={`mailto:${companyEmail}`}>
              {companyEmail}
            </Link>
          </Text>
        )}
        <Text>{company.phoneNumber}</Text>
      </View>
    </View>
  )
}
