import { Box, Grid } from '@mui/material'
import GlobalPercentagePrice from '~/routes/Pricing/BranchPricing/GlobalPercentagePrice'

import PricingTable from '~/routes/Pricing/BranchPricing/PricingTable'
import ProductCounter from '~/routes/Pricing/BranchPricing/ProductCounter'
import PageHeader from '~/components/Layouts/SalesApp/PageHeader'
import AppStyles, { sxAppStylesFlex } from '~/components/Layouts/AppStyles'
import ActionButtons from '~/components/Layouts/SalesApp/ActionButtons'
import { CompleteSetupAdminByLocation } from '~/routes/CompleteSetup/CompleteSetupAdmin'
import CompleteSetupByLocation from '~/routes/CompleteSetup/CompleteSetupByLocation'

export default function DistributorPricing() {
  // const { data: companyDetails } = useCompanyDetails()
  return (
    <>
      <ActionButtons>
        <CompleteSetupAdminByLocation />
        <CompleteSetupByLocation />
      </ActionButtons>
      <Box
        container
        sx={{
          gap: 2,
          display: { xs: 'block', md: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexGrow: 1,
        }}
      >
        <div>
          <GlobalPercentagePrice />
          <ProductCounter sx={{ display: { xs: 'block', lg: 'block' } }} />
        </div>
        <PricingTable
          sx={{ ...sxAppStylesFlex, p: 2, height: '100%', minHeight: 350 }}
        />
      </Box>
      <AppStyles />
    </>
  )
}
