import SendIcon from '@mui/icons-material/Send'
import { Stack, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { useLocation, useNavigate } from 'react-router'
import { useSWRConfig } from 'swr'
import client from '~/client'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import AutocompleteElementOptions from '~/core/forms/AutocompleteElementOptions'
import useCompanyOptions from '~/components/Users/useCompanyOptions'
import useSystemRoles from './useSystemRoles'
import useMutateUsers from '~/components/Users/useMutateUsers'

import useCompanyDetails from '~/routes/Company/Profile/useCompanyDetails'

const mapOptionsFromRoles = id => ({
  id,
  label: id
    .replace(/(?<!^)(?=[A-Z])|_+/g, ' ')
    .replace(/^\w/, c => c.toUpperCase()),
})

const getRoleOptions = ({ companyType } = {}) => {
  const allRoleOptions = client.roles.map(mapOptionsFromRoles)

  if (!companyType || client.hasRoles()) return allRoleOptions

  if (companyType === 'distributorCompany')
    return allRoleOptions.filter(role =>
      role.id.startsWith('distributor_company')
    )

  if (companyType === 'distributor')
    return [
      {
        id: 'distributor_admin',
        label: 'Distributor branch admin',
      },
      {
        id: 'distributor_user',
        label: 'Distributor branch user',
      },
    ]

  return allRoleOptions.filter(role =>
    role.id.startsWith(companyType.slice(0, 3))
  )
}

export default function AddNewUser({ disableCompany, values, companyType }) {
  const navigate = useNavigate()

  const location = useLocation()
  const user = location.state?.user
  const company = location.state?.company

  const userInfo = client.getUserInfo()

  const mutateUsers = useMutateUsers()

  const [isSubmitting, setSubmitting] = React.useState()

  const handleClick = async data => {
    const newUser = {
      ...data,
      companyId:
        data?.companyId ||
        user?.companyId ||
        company?.companyId ||
        userInfo.companyId,
    }
    try {
      setSubmitting(true)
      await client.put('protected', newUser)

      mutateUsers()

      enqueueSnackbar(`User ${newUser.email} added`)
      navigate(-1)
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setSubmitting(false)
    }
  }

  const formContext = useForm({
    defaultValues: {
      companyId:
        location?.state?.company?.companyId ||
        user?.companyId ||
        userInfo.companyId,
      // ...defaultValues,
      ...user,
    },
    shouldUnregister: true,
  })

  const companyOptions = useCompanyOptions({
    companyType, // BREAKS
  })

  const companyId = formContext.watch('companyId')

  const { data: userCompany } = useCompanyDetails()

  const selectedCompanyType =
    companyType ||
    companyOptions?.find(option => option?.id === companyId)?.companyType ||
    company?.companyType ||
    userCompany?.companyType

  console.log('AddNewUser', {
    location,
    user,
    userInfo,
    selectedCompanyType,
    companyOptions,
    companyId,
    userCompany,
  })

  return (
    <FormContainer onSuccess={handleClick} formContext={formContext}>
      <Stack spacing={3}>
        <Typography variant="h3">Add new user</Typography>
        <TextFieldElement
          fullWidth
          name="firstName"
          label="First name"
          required
        />
        <TextFieldElement
          fullWidth
          name="lastName"
          label="Last name"
          required
        />
        <TextFieldElement
          fullWidth
          name="email"
          label="Email"
          type="email"
          required
        />

        {/* {client.hasRoles([
          'unico_admin',
          'rep_admin',
          'rep_sales_manager',
          'distributor_company_admin',
          'distributor_admin',
          'distributor_user',
        ]) &&
          // !location.state?.company && */}
        {!disableCompany && (
          <AutocompleteElementOptions
            fullWidth
            name="companyId"
            label="Company"
            required
            // autocompleteProps={{
            //   disabled: !!disableCompany,
            // }}
            options={companyOptions}
          />
        )}

        <SelectElement
          fullWidth
          name="role"
          label="Role"
          required
          options={getRoleOptions({ companyType: selectedCompanyType })}
          // disabled={!selectedCompanyType}
        />

        <SubmitButton
          sx={{ flexShrink: 0, alignSelf: 'start' }}
          isSubmitting={isSubmitting}
          endIcon={<SendIcon />}
        >
          Send invitation email
        </SubmitButton>
      </Stack>
      <LoadingIndicators isSubmitting={isSubmitting} />
    </FormContainer>
  )
}
