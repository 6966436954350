import React, {
  createContext,
  useContext,
  useEffect,
  useId,
  useState,
} from 'react'

const MultiPortalContext = createContext(null)

export function MultiPortalProvider({ children }) {
  const [stacks, setStacks] = useState({})

  return (
    <MultiPortalContext.Provider value={{ stacks, setStacks }}>
      {children}
    </MultiPortalContext.Provider>
  )
}

// add replace key here to replace the content
export function useMultiPortal(name, content) {
  const { stacks, setStacks } = useContext(MultiPortalContext)
  const id = useId()

  useEffect(() => {
    setStacks(prevStacks => {
      const stack = prevStacks[name] || []
      const exists = stack.some(item => item.id === id)

      if (exists) {
        return {
          ...prevStacks,
          [name]: stack.map(item =>
            item.id === id ? { ...item, content } : item
          ),
        }
      }

      return {
        ...prevStacks,
        [name]: [...stack, { content, id }],
      }
    })
  }, [id, content, name, setStacks])

  useEffect(() => {
    return () => {
      setStacks(prevStacks => {
        const stack = prevStacks[name] || []
        return {
          ...prevStacks,
          [name]: stack.filter(item => item.id !== id),
        }
      })
    }
  }, [id, name, setStacks])
}

export function MultiPortal({ children, name, mode }) {
  useMultiPortal(name, children, { mode })
  return null
}

export const useMultiPortalOutlet = (
  name,
  content,
  { mode = 'replace' } = {}
) => {
  const { stacks } = useContext(MultiPortalContext)

  return mode === 'replace'
    ? stacks[name]?.at(-1)?.content ?? content ?? null
    : stacks[name]?.map(({ content }) => content) ?? content ?? null
}

export const MultiPortalOutlet = ({ name, children }) =>
  useMultiPortalOutlet(name, children)
